import { Filter } from './filter.model';
import { DateRange } from './date-range.model';
import { NotificationType } from './../enums/notification-type.enum';

export class NotificationFilterModel {
	public 'value'!: Filter<string>;
	public 'notificationId'!: Filter<string>;
	public 'createdDate'!: Filter<DateRange>;
	public 'notificationType'!: Filter<NotificationType[]>;
	public 'legalEntityId'!: Filter<string>;
	public 'legalEntityName'!: Filter<string>;
}
