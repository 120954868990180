import { NgIf } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';

@Component({
	standalone: true,
	selector: 'rq-icon',
	templateUrl: './icon.component.html',
	styleUrls: ['./icon.component.scss'],
	imports: [NgIf]
})
export class IconComponent {
	@HostBinding('class.icon')
	public hasIconClass = true;

	@Input()
	public href!: string;

	@HostBinding('class.icon-flip-horizontal')
	@Input()
	public flipHorizontal = false;

	@HostBinding('class.icon-flip-vertical')
	@Input()
	public flipVertical = false;

	@HostBinding('class.icon-rotate-top')
	@Input()
	public rotateTop = false;

	@HostBinding('class.icon-rotate-right')
	@Input()
	public rotateRight = false;

	@HostBinding('class.icon-rotate-bottom')
	@Input()
	public rotateBottom = false;

	@HostBinding('class.icon-rotate-left')
	@Input()
	public rotateLeft = false;

	public iconHref!: string;

	public get isInitialized() {
		if (this.href) {
			this.iconHref = `./assets/icons/symbol-defs.svg#${this.href}`;
		}

		return Boolean(this.iconHref);
	}
}
