import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { ExploitabilityLibraryUpdateViewModel } from '../../models/exploitability-library-update-view-model.model';

@Injectable()
export class ExploitabilityLibraryDataService {
	constructor(private httpService: RQHttpService) {}

	public updateRange(exploitabilityLibraries: ExploitabilityLibraryUpdateViewModel[], options = new HttpOptionsViewModel()): Promise<void> {
		options.body = exploitabilityLibraries;

		return this.httpService
			.request<void>('post', environment.api + '/api/Configuration/ExploitabilityLibrary/UpdateRange', options)
			.toPromise();
	}
}
