export const environment = {
	production: true,
	api: window.location.origin,
	identity: window.location.origin,
	logoutUrl: `${window.location.origin}/idp/Identity/Account/Logout`,
	analyticsId: '94e89173-a4d7-4287-5518-8a55c8fba552',
	thirdPartyApi: {
		securityScorecard: 'https://api.securityscorecard.io'
	},
	systemDefaultAttacks: {
		DataBreach: '7b3f7144-952e-4b96-b251-2fbcc97f0c88',
		DDoS: '385a035f-7c7b-47eb-945b-cbe6f19f7ce9 ',
		Ransomware: '76941a2a-eae7-454c-be5b-82ca99087d1d'
	},
	outsideLinks: {
		cve: 'https://nvd.nist.gov/vuln/detail/'
	},
	infiniteTimestamp: '0001-01-01T00:00:00Z'
};
