import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { ScenarioMcpCreateModel } from '../../models/scenario-mcp-create-model.model';
import { ActionResult } from '../../models/action-result.model';
import { ScenarioMcpUpdateViewModel } from '../../models/scenario-mcp-update-view-model.model';
import { ScenarioMcpViewModel } from '../../models/scenario-mcp-view-model.model';

@Injectable()
export class ScenarioMcpDataService {
	constructor(private httpService: RQHttpService) {}

	public create(scenario: ScenarioMcpCreateModel, options = new HttpOptionsViewModel()): Promise<string> {
		options.body = scenario;

		return this.httpService.request<string>('post', environment.api + '/api/Scenario/ScenarioMcp/Create', options).toPromise();
	}

	public update(scenario: ScenarioMcpUpdateViewModel, options = new HttpOptionsViewModel()): Promise<ActionResult> {
		options.body = scenario;

		return this.httpService.request<ActionResult>('put', environment.api + '/api/Scenario/ScenarioMcp/Update', options).toPromise();
	}

	public getByScenarioId(scenarioId: string, options = new HttpOptionsViewModel()): Promise<ScenarioMcpViewModel> {
		options.params = this.httpService.getHttpParams({
			scenarioId
		});

		return this.httpService
			.request<ScenarioMcpViewModel>('get', environment.api + '/api/Scenario/ScenarioMcp/GetByScenarioId', options)
			.toPromise();
	}

	public clone(
		legalEntityId: string,
		scenarioId: string,
		clonedScenarioName: string,
		options = new HttpOptionsViewModel()
	): Promise<string> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			scenarioId,
			clonedScenarioName
		});

		return this.httpService.request<string>('post', environment.api + '/api/Scenario/ScenarioMcp/Clone', options).toPromise();
	}
}
