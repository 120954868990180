import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { AnalysisStatusModel } from '../../models/analysis-status-model.model';
import { FairAnalysisStartModel } from '../../models/fair-analysis-start-model.model';
import { FairAnalysisDone } from '../../models/fair-analysis-done.model';

@Injectable()
export class FairAnalysisDataService {
	constructor(private httpService: RQHttpService) {}

	public getStatus(legalEntityId: string, scenarioId: string, options = new HttpOptionsViewModel()): Promise<AnalysisStatusModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			scenarioId
		});

		return this.httpService.request<AnalysisStatusModel>('get', environment.api + '/api/Fair/FairAnalysis/GetStatus', options).toPromise();
	}

	public startAnalysis(analysis: FairAnalysisStartModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = analysis;

		return this.httpService.request<void>('post', environment.api + '/api/Fair/FairAnalysis/StartAnalysis', options).toPromise();
	}

	public saveAnalysis(output: FairAnalysisDone, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = output;

		return this.httpService.request<void>('post', environment.api + '/api/Fair/FairAnalysis/SaveAnalysis', options).toPromise();
	}
}
