import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { ApplicationModel } from '../../models/application-model.model';

@Injectable()
export class ApplicationDataService {
	constructor(private httpService: RQHttpService) {}

	public get(options = new HttpOptionsViewModel()): Promise<ApplicationModel> {
		return this.httpService.request<ApplicationModel>('get', environment.api + '/api/Infrastructure/Application/Get', options).toPromise();
	}
}
