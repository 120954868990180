<article class="content-container">
  <section class="content-section content-section-left">
    <div>
      <img src="../../../assets/images/404.png"
           alt="Page not found" />
    </div>
  </section>
  <section class="content-section content-section-right">
    <div>
      <h1 class="content content-title">{{'pageNotFound_title_text' | translate}}</h1>
      <p class="content content-funny">{{'pageNotFound_subtitle_firstPart' | translate}}</p>
      <p class="content content-funny">{{'pageNotFound_subtitle_secondPart' | translate}}</p>
      <p class="content-info content-info-first">{{'pageNotFound_description_firstPart' | translate}}</p>
      <p class="content-info content-info-last">{{'pageNotFound_description_secondPart' | translate}}</p>

      <a (click)="navigateHome()"
         class='cursor-pointer'>{{'global_home_label' | translate}}</a>
    </div>
  </section>
</article>
