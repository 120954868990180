import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { EnterpriseProfileGroupModel } from '../../models/enterprise-profile-group-model.model';
import { EnterpriseProfileBaseModel } from '../../models/enterprise-profile-base-model.model';
import { EnterpriseProfileModel } from '../../models/enterprise-profile-model.model';
import { ActionResult } from '../../models/action-result.model';
import { EnterpriseControlCreateModel } from '../../models/enterprise-control-create-model.model';
import { EnterpriseControlUpdateModel } from '../../models/enterprise-control-update-model.model';
import { EnterpriseProfileCreateModel } from '../../models/enterprise-profile-create-model.model';

@Injectable()
export class EnterpriseProfileDataService {
	constructor(private httpService: RQHttpService) {}

	public getDefaultValuesByFrameworkId(
		controlframeworkId: string,
		options = new HttpOptionsViewModel()
	): Promise<EnterpriseProfileGroupModel[]> {
		options.params = this.httpService.getHttpParams({
			controlframeworkId
		});

		return this.httpService
			.request<EnterpriseProfileGroupModel[]>(
				'get',
				environment.api + '/api/Configuration/EnterpriseProfile/GetDefaultValuesByFrameworkId',
				options
			)
			.toPromise();
	}

	public getAll(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<EnterpriseProfileBaseModel[]> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<EnterpriseProfileBaseModel[]>('get', environment.api + '/api/Configuration/EnterpriseProfile/GetAll', options)
			.toPromise();
	}

	public getByLegalEntityId(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<EnterpriseProfileModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<EnterpriseProfileModel>('get', environment.api + '/api/Configuration/EnterpriseProfile/GetByLegalEntityId', options)
			.toPromise();
	}

	public getById(controlProfileId: string, options = new HttpOptionsViewModel()): Promise<EnterpriseProfileModel> {
		options.params = this.httpService.getHttpParams({
			controlProfileId
		});

		return this.httpService
			.request<EnterpriseProfileModel>('get', environment.api + '/api/Configuration/EnterpriseProfile/GetById', options)
			.toPromise();
	}

	public getByIds(controlProfileIds: string[], options = new HttpOptionsViewModel()): Promise<EnterpriseProfileModel[]> {
		options.body = controlProfileIds;

		return this.httpService
			.request<EnterpriseProfileModel[]>('post', environment.api + '/api/Configuration/EnterpriseProfile/GetByIds', options)
			.toPromise();
	}

	public create(createModel: EnterpriseControlCreateModel, options = new HttpOptionsViewModel()): Promise<ActionResult> {
		options.body = createModel;

		return this.httpService
			.request<ActionResult>('post', environment.api + '/api/Configuration/EnterpriseProfile/Create', options)
			.toPromise();
	}

	public update(updateModel: EnterpriseControlUpdateModel, options = new HttpOptionsViewModel()): Promise<ActionResult> {
		options.body = updateModel;

		return this.httpService
			.request<ActionResult>('post', environment.api + '/api/Configuration/EnterpriseProfile/Update', options)
			.toPromise();
	}

	public createRange(legalEntityId: string, profiles: EnterpriseProfileCreateModel[], options = new HttpOptionsViewModel()): Promise<void> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		options.body = profiles;

		return this.httpService
			.request<void>('post', environment.api + '/api/Configuration/EnterpriseProfile/CreateRange', options)
			.toPromise();
	}
}
