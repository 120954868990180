export const HUB = {
	analysis: {
		path: '/hub/analysis'
	},
	analysisSummary: {
		path: '/hub/summary'
	},
	integration: {
		path: '/hub/integration'
	},
	systemHealth: {
		path: '/hub/systemHealth'
	},
	notification: {
		path: '/hub/notification'
	},
	snowflake: {
		path: '/hub/snowflake'
	}
};
