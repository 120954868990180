import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { MitreTechniqueFrequencyModel } from '../../models/mitre-technique-frequency-model.model';
import { IActionResult } from '../../models/iaction-result.model';
import { MitreTechniqueFrequencyUpdateModel } from '../../models/mitre-technique-frequency-update-model.model';

@Injectable()
export class MitreTechniqueFrequencyDataService {
	constructor(private httpService: RQHttpService) {}

	public getByLegalEntityId(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<MitreTechniqueFrequencyModel[]> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<MitreTechniqueFrequencyModel[]>('get', environment.api + '/api/Settings/MitreTechniqueFrequency/GetByLegalEntityId', options)
			.toPromise();
	}

	public update(updateModel: MitreTechniqueFrequencyUpdateModel, options = new HttpOptionsViewModel()): Promise<IActionResult> {
		options.body = updateModel;

		return this.httpService
			.request<IActionResult>('put', environment.api + '/api/Settings/MitreTechniqueFrequency/Update', options)
			.toPromise();
	}

	public revertDefaults(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<void> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<void>('put', environment.api + '/api/Settings/MitreTechniqueFrequency/RevertDefaults', options)
			.toPromise();
	}
}
