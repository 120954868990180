/* eslint-disable sonarjs/no-duplicate-string */

const primitives = {
	'color-light-100': '#ffffff',
	'color-dark-100': '#333333',

	'color-gray-100': '#f6f6f6',
	'color-gray-150': '#ededed',
	'color-gray-200': '#e8e8e8',
	'color-gray-300': '#a4a4a6',

	'color-primary-050': '#737d81',
	'color-primary-100': '#596367',
	'color-primary-200': '#3a4043',

	'color-accent-025': '#fff2ee',
	'color-accent-050': '#ffd8cc',
	'color-accent-100': '#ff7a4f',
	'color-accent-200': '#ff5c28',

	'color-success-025': '#f3faec',
	'color-success-050': '#e7f6d9',
	'color-success-100': '#b0dd7a',
	'color-success-200': '#75b259',

	'color-danger-025': '#fff8f6',
	'color-danger-050': '#ef9a9a',
	'color-danger-100': '#ef5350',
	'color-danger-200': '#ea1915',

	'color-info-025': '#f1f8fe',
	'color-info-050': '#90caf9',
	'color-info-100': '#42a5f5',
	'color-info-200': '#1976d2',

	'color-warning-025': '#fffbf0',
	'color-warning-050': '#ffe082',
	'color-warning-100': '#ffca28',
	'color-warning-200': '#ffb300',

	'color-royal-025': '#f4effa',
	'color-royal-050': '#d2b7e5',
	'color-royal-100': '#a06cd5',
	'color-royal-200': '#6247aa',

	'color-clear-025': '#eafcf9',
	'color-clear-050': '#d6f9f3',
	'color-clear-100': '#97efe2',
	'color-clear-200': '#44e2ca'
};

const colors = {
	'color-light': primitives['color-light-100'],
	'color-dark': primitives['color-dark-100'],

	'color-gray': primitives['color-gray-200'],
	'color-gray-soft': primitives['color-gray-100'],
	'color-gray-light': primitives['color-gray-150'],
	'color-gray-dark': primitives['color-gray-300'],

	'color-accent': primitives['color-accent-100'],
	'color-accent-light': primitives['color-accent-050'],
	'color-accent-dark': primitives['color-accent-200'],
	'color-accent-soft': primitives['color-accent-025'],

	'color-primary': primitives['color-primary-100'],
	'color-primary-light': primitives['color-primary-050'],
	'color-primary-dark': primitives['color-primary-200'],

	'color-success': primitives['color-success-100'],
	'color-success-soft': primitives['color-success-025'],
	'color-success-light': primitives['color-success-050'],
	'color-success-dark': primitives['color-success-200'],

	'color-danger': primitives['color-danger-100'],
	'color-danger-soft': primitives['color-danger-025'],
	'color-danger-light': primitives['color-danger-050'],
	'color-danger-dark': primitives['color-danger-200'],

	'color-info': primitives['color-info-100'],
	'color-info-soft': primitives['color-info-025'],
	'color-info-light': primitives['color-info-050'],
	'color-info-dark': primitives['color-info-200'],

	'color-warning': primitives['color-warning-100'],
	'color-warning-soft': primitives['color-warning-025'],
	'color-warning-light': primitives['color-warning-050'],
	'color-warning-dark': primitives['color-warning-200'],

	'color-royal': primitives['color-royal-100'],
	'color-royal-soft': primitives['color-royal-025'],
	'color-royal-light': primitives['color-royal-050'],
	'color-royal-dark': primitives['color-royal-200'],

	'color-clear': primitives['color-clear-100'],
	'color-clear-soft': primitives['color-clear-025'],
	'color-clear-light': primitives['color-clear-050'],
	'color-clear-dark': primitives['color-clear-200']
};

export const defaultThemeColors = {
	...colors,

	// Text Global Colors
	'text-color-primary': colors['color-dark'],
	'text-color-secondary': colors['color-primary'],
	'text-color-light': colors['color-light'],
	'text-color-accent': colors['color-accent'],
	'text-color-disabled': colors['color-gray-dark'],
	'text-color-info': colors['color-info'],
	'text-color-link': colors['color-info-dark'],
	'text-color-danger': colors['color-danger'],
	'text-color-danger-dark': colors['color-danger-dark'],
	'text-color-success': colors['color-success'],
	'text-color-success-dark': colors['color-success-dark'],
	'text-color-dark': colors['color-primary-dark'],
	'text-color-warning': colors['color-warning'],
	'text-color-warning-dark': colors['color-warning-dark'],
	'text-color-royal': colors['color-royal'],
	'text-color-royal-dark': colors['color-royal-dark'],
	'text-color-clear': colors['color-clear'],
	'text-color-clear-dark': colors['color-clear-dark'],
	'text-highlight-background': colors['color-gray-light'],

	// Background Global Colors
	'background-color-light': colors['color-light'],
	'background-color-dark': colors['color-dark'],

	'background-color-gray-soft': colors['color-gray-soft'],
	'background-color-gray-light': colors['color-gray-light'],
	'background-color-gray-dark': colors['color-gray-dark'],

	'background-color-accent': colors['color-accent'],
	'background-color-accent-dark': colors['color-accent-dark'],
	'background-color-accent-light': colors['color-accent-light'],
	'background-color-accent-soft': colors['color-accent-soft'],

	'background-color-primary': colors['color-primary'],
	'background-color-primary-dark': colors['color-primary-dark'],
	'background-color-primary-light': colors['color-primary-light'],

	'background-color-danger': colors['color-danger'],
	'background-color-danger-dark': colors['color-danger-dark'],
	'background-color-danger-light': colors['color-danger-light'],
	'background-color-danger-soft': colors['color-danger-soft'],

	'background-color-success': colors['color-success'],
	'background-color-success-dark': colors['color-success-dark'],
	'background-color-success-light': colors['color-success-light'],
	'background-color-success-soft': colors['color-success-soft'],

	'background-color-info': colors['color-info'],
	'background-color-info-dark': colors['color-info-dark'],
	'background-color-info-light': colors['color-info-light'],
	'background-color-info-soft': colors['color-info-soft'],

	'background-color-warning': colors['color-warning'],
	'background-color-warning-dark': colors['color-warning-dark'],
	'background-color-warning-light': colors['color-warning-light'],
	'background-color-warning-soft': colors['color-warning-soft'],

	'background-color-royal': colors['color-royal'],
	'background-color-royal-dark': colors['color-royal-dark'],
	'background-color-royal-light': colors['color-royal-light'],
	'background-color-royal-soft': colors['color-royal-soft'],

	'background-color-clear': colors['color-clear'],
	'background-color-clear-dark': colors['color-clear-dark'],
	'background-color-clear-light': colors['color-clear-light'],
	'background-color-clear-soft': colors['color-clear-soft'],

	'background-overlay': 'rgba(51, 51, 51, 0.5)',

	// Border Global Colors
	'border-color': colors['color-gray'],
	'border-color-disabled': colors['color-gray-soft'],

	'border-color-light': colors['color-light'],
	'border-color-dark': colors['color-dark'],

	'border-color-gray-soft': colors['color-gray-soft'],
	'border-color-gray-light': colors['color-gray-light'],
	'border-color-gray-dark': colors['color-gray-dark'],

	'border-color-accent': colors['color-accent'],
	'border-color-accent-dark': colors['color-accent-dark'],
	'border-color-accent-light': colors['color-accent-light'],

	'border-color-primary': colors['color-primary'],
	'border-color-primary-dark': colors['color-primary-dark'],
	'border-color-primary-light': colors['color-primary-light'],

	'border-color-danger': colors['color-danger'],
	'border-color-danger-dark': colors['color-danger-dark'],
	'border-color-danger-light': colors['color-danger-light'],

	'border-color-success': colors['color-success'],
	'border-color-success-dark': colors['color-success-dark'],
	'border-color-success-light': colors['color-success-light'],

	'border-color-info': colors['color-info'],
	'border-color-info-dark': colors['color-info-dark'],
	'border-color-info-light': colors['color-info-light'],

	'border-color-warning': colors['color-warning'],
	'border-color-warning-dark': colors['color-warning-dark'],
	'border-color-warning-light': colors['color-warning-light'],

	//Shadow
	'shadow-xs': '0px 1px 3px 0px rgba(115, 125, 129, 0.05), 0px 1px 2px -1px rgba(115, 125, 129, 0.05)',
	'shadow-sm': '0 4px 10px 0 rgba(115, 125, 129, 0.10)',
	'shadow-md': '0 8px 16px -5px rgba(115, 125, 129, 0.15)',
	'shadow-lg': '0 0 20px 0 rgba(115, 125, 129, 0.20)',
	'shadow-left': '-6px 0 10px 0 rgba(115, 125, 129, 0.20)',
	'shadow-right': '-6px 0 10px 0 rgba(115, 125, 129, 0.20)'
};
