import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { SettingsViewModel } from '../../models/settings-view-model.model';

@Injectable()
export class SettingsDataService {
	constructor(private httpService: RQHttpService) {}

	public getChartColors(options = new HttpOptionsViewModel()): Promise<string[]> {
		return this.httpService.request<string[]>('get', environment.api + '/api/Infrastructure/Settings/GetChartColors', options).toPromise();
	}

	public getRecommendationsMinPS(options = new HttpOptionsViewModel()): Promise<number> {
		return this.httpService
			.request<number>('get', environment.api + '/api/Infrastructure/Settings/GetRecommendationsMinPS', options)
			.toPromise();
	}

	public isBeta(options = new HttpOptionsViewModel()): Promise<boolean> {
		return this.httpService.request<boolean>('get', environment.api + '/api/Infrastructure/Settings/IsBeta', options).toPromise();
	}

	public getSettings(options = new HttpOptionsViewModel()): Promise<SettingsViewModel> {
		return this.httpService
			.request<SettingsViewModel>('get', environment.api + '/api/Infrastructure/Settings/GetSettings', options)
			.toPromise();
	}
}
