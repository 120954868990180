import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { RecommendationCostCreateViewModel } from '../../models/recommendation-cost-create-view-model.model';
import { RecommendationCostUpdateViewModel } from '../../models/recommendation-cost-update-view-model.model';

@Injectable()
export class FairRecommendationCostDataService {
	constructor(private httpService: RQHttpService) {}

	public create(implementation: RecommendationCostCreateViewModel, options = new HttpOptionsViewModel()): Promise<string> {
		options.body = implementation;

		return this.httpService.request<string>('post', environment.api + '/api/Fair/FairRecommendationCost/Create', options).toPromise();
	}

	public update(implementation: RecommendationCostUpdateViewModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = implementation;

		return this.httpService.request<void>('post', environment.api + '/api/Fair/FairRecommendationCost/Update', options).toPromise();
	}

	public delete(fairImplementationId: string, options = new HttpOptionsViewModel()): Promise<void> {
		options.params = this.httpService.getHttpParams({
			fairImplementationId
		});

		return this.httpService.request<void>('delete', environment.api + '/api/Fair/FairRecommendationCost/Delete', options).toPromise();
	}
}
