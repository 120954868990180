import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { EnterpriseImpactVectorModel } from '../../models/enterprise-impact-vector-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';

@Injectable()
export class EnterpriseImpactVectorDataService {
	constructor(private httpService: RQHttpService) {}

	public getPaginated(
		pagination: PaginationFilter,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<EnterpriseImpactVectorModel>> {
		options.params = this.httpService.getHttpParams({
			pagination
		});

		return this.httpService
			.request<Pagination<EnterpriseImpactVectorModel>>(
				'get',
				environment.api + '/api/Enterprise/EnterpriseImpactVector/GetPaginated',
				options
			)
			.toPromise();
	}

	public export(options = new HttpOptionsViewModel()): Promise<Blob> {
		options.responseType = options.responseType ? options.responseType : 'blob';
		return this.httpService.request<Blob>('get', environment.api + '/api/Enterprise/EnterpriseImpactVector/Export', options).toPromise();
	}
}
