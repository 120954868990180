import * as signalR from '@microsoft/signalr';
import { environment } from '../../../../environments/environment';
import { ConsoleLoggerService } from '../../services/console-logger.service';
import { ConsoleLoggerModel } from '../../models/console-logger.model';

export class BaseHubService {
	protected connection?: signalR.HubConnection;

	private RECONNECT_COUNT = 1000;

	constructor(private path: string, private loggerService: ConsoleLoggerService) {}

	public get reconnectRetryCount() {
		const reconnectRetryCount: number[] = [];

		for (let i = 0; i < this.RECONNECT_COUNT; i++) {
			reconnectRetryCount.push(100);
		}

		return reconnectRetryCount;
	}

	public async connect() {
		if (!this.connection) {
			const logLevel = environment.production ? signalR.LogLevel.Information : signalR.LogLevel.Debug;

			this.connection = new signalR.HubConnectionBuilder()
				.withUrl(`${environment.api}${this.path}`)
				.configureLogging(logLevel)
				.withAutomaticReconnect(this.reconnectRetryCount)
				.build();
		}

		await this.connection
			.start()
			.then(() => this.loggerService.logDev(new ConsoleLoggerModel(`${this.path} connection has started`)))
			.catch(error => {
				this.loggerService.logDev({ message: `${this.path} connection has failed to start` });
				this.loggerService.logDev({ message: error as string });
			});
	}

	public async disconnect() {
		if (this.connection) {
			await this.connection.stop();
			this.connection = undefined;
		}
	}
}
