import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { CveExploitabilityWeightingModel } from '../../models/cve-exploitability-weighting-model.model';

@Injectable()
export class CveExploitabilityWeightingDataService {
	constructor(private httpService: RQHttpService) {}

	public getByLegalEntity(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<CveExploitabilityWeightingModel[]> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<CveExploitabilityWeightingModel[]>(
				'get',
				environment.api + '/api/Settings/CveExploitabilityWeighting/GetByLegalEntity',
				options
			)
			.toPromise();
	}

	public update(exploitabilityWeighting: CveExploitabilityWeightingModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = exploitabilityWeighting;

		return this.httpService.request<void>('put', environment.api + '/api/Settings/CveExploitabilityWeighting/Update', options).toPromise();
	}
}
