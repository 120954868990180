import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { LicenseModel } from '../../models/license-model.model';

@Injectable()
export class LicenseDataService {
	constructor(private httpService: RQHttpService) {}

	public get(options = new HttpOptionsViewModel()): Promise<LicenseModel> {
		return this.httpService.request<LicenseModel>('get', environment.api + '/api/Settings/License/Get', options).toPromise();
	}

	public isValid(options = new HttpOptionsViewModel()): Promise<boolean> {
		return this.httpService.request<boolean>('get', environment.api + '/api/Settings/License/IsValid', options).toPromise();
	}
}
