import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { DataProfileModel } from '../../models/data-profile-model.model';
import { DataProfileCreateModel } from '../../models/data-profile-create-model.model';
import { TpDataProfileCreateViewModel } from '../../models/tp-data-profile-create-view-model.model';
import { DataProfileCreateRangeModel } from '../../models/data-profile-create-range-model.model';
import { DataProfileUpdateModel } from '../../models/data-profile-update-model.model';

@Injectable()
export class DataProfileDataService {
	constructor(private httpService: RQHttpService) {}

	public getById(id: string, options = new HttpOptionsViewModel()): Promise<DataProfileModel> {
		options.params = this.httpService.getHttpParams({
			id
		});

		return this.httpService
			.request<DataProfileModel>('get', environment.api + '/api/Configuration/DataProfile/GetById', options)
			.toPromise();
	}

	public getByLossExposureId(lossExposureId: string, options = new HttpOptionsViewModel()): Promise<DataProfileModel> {
		options.params = this.httpService.getHttpParams({
			lossExposureId
		});

		return this.httpService
			.request<DataProfileModel>('get', environment.api + '/api/Configuration/DataProfile/GetByLossExposureId', options)
			.toPromise();
	}

	public create(profileModel: DataProfileCreateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = profileModel;

		return this.httpService.request<void>('post', environment.api + '/api/Configuration/DataProfile/Create', options).toPromise();
	}

	public createDataExposure(
		legalEntityId: string,
		tpDataProfile: TpDataProfileCreateViewModel,
		options = new HttpOptionsViewModel()
	): Promise<string> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		options.body = tpDataProfile;

		return this.httpService
			.request<string>('post', environment.api + '/api/Configuration/DataProfile/CreateDataExposure', options)
			.toPromise();
	}

	public createRange(dataProfiles: DataProfileCreateRangeModel[], options = new HttpOptionsViewModel()): Promise<void> {
		options.body = dataProfiles;

		return this.httpService.request<void>('post', environment.api + '/api/Configuration/DataProfile/CreateRange', options).toPromise();
	}

	public update(profileModel: DataProfileUpdateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = profileModel;

		return this.httpService.request<void>('put', environment.api + '/api/Configuration/DataProfile/Update', options).toPromise();
	}
}
