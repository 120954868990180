import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { ActionResult } from '../../models/action-result.model';
import { FinancialLegalEntityUpdateViewModel } from '../../models/financial-legal-entity-update-view-model.model';
import { FinancialLegalEntityViewModel } from '../../models/financial-legal-entity-view-model.model';

@Injectable()
export class FinancialLegalEntityDataService {
	constructor(private httpService: RQHttpService) {}

	public update(
		legalEntityId: string,
		legalEntity: FinancialLegalEntityUpdateViewModel,
		options = new HttpOptionsViewModel()
	): Promise<ActionResult> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		options.body = legalEntity;

		return this.httpService
			.request<ActionResult>('put', environment.api + '/api/FinancialAnalysis/FinancialLegalEntity/Update', options)
			.toPromise();
	}

	public getByLegalEntityId(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<FinancialLegalEntityViewModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<FinancialLegalEntityViewModel>(
				'get',
				environment.api + '/api/FinancialAnalysis/FinancialLegalEntity/GetByLegalEntityId',
				options
			)
			.toPromise();
	}
}
