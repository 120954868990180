import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { ScenarioAnalysisConfigurationModel } from '../../models/scenario-analysis-configuration-model.model';

@Injectable()
export class ScenarioAnalysisConfigurationDataService {
	constructor(private httpService: RQHttpService) {}

	public getByScenarioId(scenarioId: string, options = new HttpOptionsViewModel()): Promise<ScenarioAnalysisConfigurationModel> {
		options.params = this.httpService.getHttpParams({
			scenarioId
		});

		return this.httpService
			.request<ScenarioAnalysisConfigurationModel>(
				'get',
				environment.api + '/api/Scenario/ScenarioAnalysisConfiguration/GetByScenarioId',
				options
			)
			.toPromise();
	}
}
