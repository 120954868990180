import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { FairScenarioDetails } from '../../models/fair-scenario-details.model';
import { PaginationFilter } from '../../models/pagination-filter.model';

@Injectable()
export class AggregatedFairAnalysisDataService {
	constructor(private httpService: RQHttpService) {}

	public getDetails(
		scenarioId: string,
		pagination: PaginationFilter,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<FairScenarioDetails>> {
		options.params = this.httpService.getHttpParams({
			scenarioId,
			pagination
		});

		return this.httpService
			.request<Pagination<FairScenarioDetails>>('get', environment.api + '/api/Fair/AggregatedFairAnalysis/GetDetails', options)
			.toPromise();
	}
}
