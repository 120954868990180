import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { LossValueModel } from '../../models/loss-value-model.model';
import { LossValueUpdateModel } from '../../models/loss-value-update-model.model';

@Injectable()
export class LossValueDataService {
	constructor(private httpService: RQHttpService) {}

	public getByLegalEntityId(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<LossValueModel[]> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<LossValueModel[]>('get', environment.api + '/api/Settings/LossValue/GetByLegalEntityId', options)
			.toPromise();
	}

	public update(lossValue: LossValueUpdateModel, options = new HttpOptionsViewModel()): Promise<string> {
		options.body = lossValue;

		return this.httpService.request<string>('put', environment.api + '/api/Settings/LossValue/Update', options).toPromise();
	}

	public revertDefaults(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<void> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService.request<void>('put', environment.api + '/api/Settings/LossValue/RevertDefaults', options).toPromise();
	}
}
