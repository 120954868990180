import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { BaEnterpriseProfileModel } from '../../models/ba-enterprise-profile-model.model';
import { ApplicationEnterpriseProfileType } from '../../enums/application-enterprise-profile-type.enum';
import { BaEnterpriseProfileUpdateModel } from '../../models/ba-enterprise-profile-update-model.model';
import { BaEnterpriseProfileCreateModel } from '../../models/ba-enterprise-profile-create-model.model';
import { BaEnterpriseProfileDeleteModel } from '../../models/ba-enterprise-profile-delete-model.model';

@Injectable()
export class BaEnterpriseProfileDataService {
	constructor(private httpService: RQHttpService) {}

	public getByApplicationId(
		businessApplicationId: string,
		type: ApplicationEnterpriseProfileType,
		options = new HttpOptionsViewModel()
	): Promise<BaEnterpriseProfileModel> {
		options.params = this.httpService.getHttpParams({
			businessApplicationId,
			type
		});

		return this.httpService
			.request<BaEnterpriseProfileModel>('get', environment.api + '/api/Configuration/BaEnterpriseProfile/GetByApplicationId', options)
			.toPromise();
	}

	public update(assignation: BaEnterpriseProfileUpdateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = assignation;

		return this.httpService.request<void>('put', environment.api + '/api/Configuration/BaEnterpriseProfile/Update', options).toPromise();
	}

	public updateRange(assignations: BaEnterpriseProfileUpdateModel[], options = new HttpOptionsViewModel()): Promise<void> {
		options.body = assignations;

		return this.httpService
			.request<void>('put', environment.api + '/api/Configuration/BaEnterpriseProfile/UpdateRange', options)
			.toPromise();
	}

	public create(assignation: BaEnterpriseProfileCreateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = assignation;

		return this.httpService.request<void>('post', environment.api + '/api/Configuration/BaEnterpriseProfile/Create', options).toPromise();
	}

	public delete(assignation: BaEnterpriseProfileDeleteModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = assignation;

		return this.httpService.request<void>('post', environment.api + '/api/Configuration/BaEnterpriseProfile/Delete', options).toPromise();
	}
}
