import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { RevenueLossExposureModel } from '../../models/revenue-loss-exposure-model.model';
import { RevenueLossExposureUpdateModel } from '../../models/revenue-loss-exposure-update-model.model';
import { RevenueLossExposureCreateModel } from '../../models/revenue-loss-exposure-create-model.model';
import { RevenueLossExposureFullCreateModel } from '../../models/revenue-loss-exposure-full-create-model.model';
import { RevenueLossExposureTotalModel } from '../../models/revenue-loss-exposure-total-model.model';
import { RevenueLossExposureDetailsModel } from '../../models/revenue-loss-exposure-details-model.model';

@Injectable()
export class RevenueLossExposureDataService {
	constructor(private httpService: RQHttpService) {}

	public getByLossExposureId(lossExposureId: string, options = new HttpOptionsViewModel()): Promise<RevenueLossExposureModel> {
		options.params = this.httpService.getHttpParams({
			lossExposureId
		});

		return this.httpService
			.request<RevenueLossExposureModel>('get', environment.api + '/api/Configuration/RevenueLossExposure/GetByLossExposureId', options)
			.toPromise();
	}

	public update(model: RevenueLossExposureUpdateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = model;

		return this.httpService.request<void>('post', environment.api + '/api/Configuration/RevenueLossExposure/Update', options).toPromise();
	}

	public create(model: RevenueLossExposureCreateModel, options = new HttpOptionsViewModel()): Promise<string> {
		options.body = model;

		return this.httpService.request<string>('post', environment.api + '/api/Configuration/RevenueLossExposure/Create', options).toPromise();
	}

	public bulkCreate(models: RevenueLossExposureFullCreateModel[], options = new HttpOptionsViewModel()): Promise<void> {
		options.body = models;

		return this.httpService
			.request<void>('post', environment.api + '/api/Configuration/RevenueLossExposure/BulkCreate', options)
			.toPromise();
	}

	public getTotals(revenueLossExposureId: string, options = new HttpOptionsViewModel()): Promise<RevenueLossExposureTotalModel> {
		options.params = this.httpService.getHttpParams({
			revenueLossExposureId
		});

		return this.httpService
			.request<RevenueLossExposureTotalModel>('get', environment.api + '/api/Configuration/RevenueLossExposure/GetTotals', options)
			.toPromise();
	}

	public getDetails(lossExposureId: string, options = new HttpOptionsViewModel()): Promise<RevenueLossExposureDetailsModel> {
		options.params = this.httpService.getHttpParams({
			lossExposureId
		});

		return this.httpService
			.request<RevenueLossExposureDetailsModel>('get', environment.api + '/api/Configuration/RevenueLossExposure/GetDetails', options)
			.toPromise();
	}
}
