import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { CustomModelViewModel } from '../../models/custom-model-view-model.model';
import { Pagination } from '../../models/pagination.model';
import { CustomModelDefinitionViewModel } from '../../models/custom-model-definition-view-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { CustomModelFilterViewModel } from '../../models/custom-model-filter-view-model.model';
import { IActionResult } from '../../models/iaction-result.model';
import { CustomModelUpdateViewModel } from '../../models/custom-model-update-view-model.model';
import { CustomModelCreateViewModel } from '../../models/custom-model-create-view-model.model';

@Injectable()
export class EnterpriseCustomModelsDataService {
	constructor(private httpService: RQHttpService) {}

	public getById(customModelId: string, options = new HttpOptionsViewModel()): Promise<CustomModelViewModel> {
		options.params = this.httpService.getHttpParams({
			customModelId
		});

		return this.httpService
			.request<CustomModelViewModel>('get', environment.api + '/api/Enterprise/EnterpriseCustomModels/GetById', options)
			.toPromise();
	}

	public getCurrencyModels(options = new HttpOptionsViewModel()): Promise<CustomModelViewModel[]> {
		return this.httpService
			.request<CustomModelViewModel[]>('get', environment.api + '/api/Enterprise/EnterpriseCustomModels/GetCurrencyModels', options)
			.toPromise();
	}

	public getPaginated(
		paginationModel: PaginationFilter,
		filterModel: CustomModelFilterViewModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<CustomModelDefinitionViewModel>> {
		options.params = this.httpService.getHttpParams({
			paginationModel,
			filterModel
		});

		return this.httpService
			.request<Pagination<CustomModelDefinitionViewModel>>(
				'get',
				environment.api + '/api/Enterprise/EnterpriseCustomModels/GetPaginated',
				options
			)
			.toPromise();
	}

	public hasUniqueName(modelName: string, options = new HttpOptionsViewModel()): Promise<boolean> {
		options.params = this.httpService.getHttpParams({
			modelName
		});

		return this.httpService
			.request<boolean>('get', environment.api + '/api/Enterprise/EnterpriseCustomModels/HasUniqueName', options)
			.toPromise();
	}

	public update(updateModel: CustomModelUpdateViewModel, options = new HttpOptionsViewModel()): Promise<IActionResult> {
		options.body = updateModel;

		return this.httpService
			.request<IActionResult>('put', environment.api + '/api/Enterprise/EnterpriseCustomModels/Update', options)
			.toPromise();
	}

	public create(createViewModel: CustomModelCreateViewModel, options = new HttpOptionsViewModel()): Promise<string> {
		options.body = createViewModel;

		return this.httpService.request<string>('post', environment.api + '/api/Enterprise/EnterpriseCustomModels/Create', options).toPromise();
	}

	public delete(customModelId: string, options = new HttpOptionsViewModel()): Promise<IActionResult> {
		options.params = this.httpService.getHttpParams({
			customModelId
		});

		return this.httpService
			.request<IActionResult>('delete', environment.api + '/api/Enterprise/EnterpriseCustomModels/Delete', options)
			.toPromise();
	}

	public clone(customModelId: string, clonedLossModelName: string, options = new HttpOptionsViewModel()): Promise<string> {
		options.params = this.httpService.getHttpParams({
			customModelId,
			clonedLossModelName
		});

		return this.httpService.request<string>('post', environment.api + '/api/Enterprise/EnterpriseCustomModels/Clone', options).toPromise();
	}
}
