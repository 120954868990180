export const ROLE_CONFIG = {
	instance: {
		administrator: 'rq_instance_administrator'
	},
	pro: {
		administrator: 'rq_pro_administrator',
		editor: 'rq_pro_editor',
		exportApi: 'rq_export_api',
		readOnly: 'rq_pro_read_only',
		fairOnly: 'rq_fair_only'
	},
	enterprise: {
		administrator: 'rq_enterprise_administrator',
		readOnly: 'rq_enterprise_read_only'
	},
	all: 'all'
};
