import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { ApplicationState } from '../../enums/application-state.enum';

@Injectable()
export class SystemHealthDataService {
	constructor(private httpService: RQHttpService) {}

	public getState(options = new HttpOptionsViewModel()): Promise<ApplicationState> {
		return this.httpService.request<ApplicationState>('get', environment.api + '/api/Health/SystemHealth/GetState', options).toPromise();
	}
}
