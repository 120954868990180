import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { IActionResult } from '../../models/iaction-result.model';
import { Log } from '../../models/log.model';

@Injectable()
export class LogsDataService {
	constructor(private httpService: RQHttpService) {}

	public post(log: Log, options = new HttpOptionsViewModel()): Promise<IActionResult> {
		options.body = log;

		return this.httpService.request<IActionResult>('post', environment.api + '/api/Infrastructure/Logs/Post', options).toPromise();
	}
}
