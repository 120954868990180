import { Pipe, PipeTransform } from '@angular/core';
import { TranslateParam } from './models/translate.model';
import { Customizer } from '../../core/services/customizer';

@Pipe({
	name: 'translate',
	standalone: true
})
export class TranslatePipe implements PipeTransform {
	constructor(private customizer: Customizer) {}

	public transform(key?: string, params?: TranslateParam): string {
		return key ? this.customizer.translate(key, params) : '';
	}
}
