import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { FairTreeModel } from '../../models/fair-tree-model.model';
import { FairCharacteristicOfLossModel } from '../../models/fair-characteristic-of-loss-model.model';

@Injectable()
export class EnterpriseFairAnalysisDetailsDataService {
	constructor(private httpService: RQHttpService) {}

	public getOutputs(scenarioId: string, legalEntityId: string, options = new HttpOptionsViewModel()): Promise<FairTreeModel> {
		options.params = this.httpService.getHttpParams({
			scenarioId,
			legalEntityId
		});

		return this.httpService
			.request<FairTreeModel>('get', environment.api + '/api/Enterprise/EnterpriseFairAnalysisDetails/GetOutputs', options)
			.toPromise();
	}

	public getCharacterisicOfLoss(
		scenarioid: string,
		legalEntityId: string,
		options = new HttpOptionsViewModel()
	): Promise<FairCharacteristicOfLossModel> {
		options.params = this.httpService.getHttpParams({
			scenarioid,
			legalEntityId
		});

		return this.httpService
			.request<FairCharacteristicOfLossModel>(
				'get',
				environment.api + '/api/Enterprise/EnterpriseFairAnalysisDetails/GetCharacterisicOfLoss',
				options
			)
			.toPromise();
	}
}
