import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { FairConfidenceTypeModel } from '../../models/fair-confidence-type-model.model';

@Injectable()
export class FairConfidenceTypeDataService {
	constructor(private httpService: RQHttpService) {}

	public getAll(options = new HttpOptionsViewModel()): Promise<FairConfidenceTypeModel[]> {
		return this.httpService
			.request<FairConfidenceTypeModel[]>('get', environment.api + '/api/Scenario/FairConfidenceType/GetAll', options)
			.toPromise();
	}
}
