import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { LocationModel } from '../../models/location-model.model';
import { LocationType } from '../../enums/location-type.enum';

@Injectable()
export class LocationDataService {
	constructor(private httpService: RQHttpService) {}

	public getAll(options = new HttpOptionsViewModel()): Promise<LocationModel[]> {
		return this.httpService.request<LocationModel[]>('get', environment.api + '/api/Configuration/Location/GetAll', options).toPromise();
	}

	public getByLocation(locationType: LocationType, options = new HttpOptionsViewModel()): Promise<LocationModel[]> {
		options.params = this.httpService.getHttpParams({
			locationType
		});

		return this.httpService
			.request<LocationModel[]>('get', environment.api + '/api/Configuration/Location/GetByLocation', options)
			.toPromise();
	}
}
