import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { ScenarioAnalysisStartModel } from '../../models/scenario-analysis-start-model.model';
import { TTPAnalysisDone } from '../../models/ttpanalysis-done.model';

@Injectable()
export class MitreRunnerDataService {
	constructor(private httpService: RQHttpService) {}

	public startAnalysis(analysis: ScenarioAnalysisStartModel, options = new HttpOptionsViewModel()): Promise<string> {
		options.body = analysis;

		return this.httpService.request<string>('post', environment.api + '/api/Scenario/MitreRunner/StartAnalysis', options).toPromise();
	}

	public saveAnalysis(output: TTPAnalysisDone, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = output;

		return this.httpService.request<void>('post', environment.api + '/api/Scenario/MitreRunner/SaveAnalysis', options).toPromise();
	}
}
