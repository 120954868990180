import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { FairInputModel } from '../../models/fair-input-model.model';
import { FairType } from '../../enums/fair-type.enum';
import { FairTreeModel } from '../../models/fair-tree-model.model';
import { FairCreateInputModel } from '../../models/fair-create-input-model.model';
import { FairUpdateInputModel } from '../../models/fair-update-input-model.model';

@Injectable()
export class FairInputDataService {
	constructor(private httpService: RQHttpService) {}

	public getByType(scenarioId: string, type: FairType, options = new HttpOptionsViewModel()): Promise<FairInputModel> {
		options.params = this.httpService.getHttpParams({
			scenarioId,
			type
		});

		return this.httpService.request<FairInputModel>('get', environment.api + '/api/Scenario/FairInput/GetByType', options).toPromise();
	}

	public getByTypes(scenarioId: string, types: FairType[], options = new HttpOptionsViewModel()): Promise<FairInputModel[]> {
		options.params = this.httpService.getHttpParams({
			scenarioId,
			types
		});

		return this.httpService.request<FairInputModel[]>('get', environment.api + '/api/Scenario/FairInput/GetByTypes', options).toPromise();
	}

	public getByScenarioId(scenarioId: string, options = new HttpOptionsViewModel()): Promise<FairInputModel[]> {
		options.params = this.httpService.getHttpParams({
			scenarioId
		});

		return this.httpService
			.request<FairInputModel[]>('get', environment.api + '/api/Scenario/FairInput/GetByScenarioId', options)
			.toPromise();
	}

	public getSummary(scenarioId: string, options = new HttpOptionsViewModel()): Promise<FairTreeModel> {
		options.params = this.httpService.getHttpParams({
			scenarioId
		});

		return this.httpService.request<FairTreeModel>('get', environment.api + '/api/Scenario/FairInput/GetSummary', options).toPromise();
	}

	public create(fairInputs: FairCreateInputModel[], options = new HttpOptionsViewModel()): Promise<void> {
		options.body = fairInputs;

		return this.httpService.request<void>('post', environment.api + '/api/Scenario/FairInput/Create', options).toPromise();
	}

	public update(fairInputs: FairUpdateInputModel[], options = new HttpOptionsViewModel()): Promise<void> {
		options.body = fairInputs;

		return this.httpService.request<void>('post', environment.api + '/api/Scenario/FairInput/Update', options).toPromise();
	}

	public deleteByTypes(scenarioId: string, types: FairType[], options = new HttpOptionsViewModel()): Promise<void> {
		options.params = this.httpService.getHttpParams({
			scenarioId,
			types
		});

		return this.httpService.request<void>('delete', environment.api + '/api/Scenario/FairInput/DeleteByTypes', options).toPromise();
	}
}
