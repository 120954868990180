import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class CachingService {
	public setItem(cacheKey: string, value: object | string | boolean, isPersistent = true) {
		const storage = isPersistent ? localStorage : sessionStorage;

		typeof value === 'object' ? storage.setItem(cacheKey, JSON.stringify(value)) : storage.setItem(cacheKey, String(value));
	}

	public getItem<T>(cacheKey: string): T | null {
		const localStorageItem = localStorage.getItem(cacheKey);
		if (localStorageItem) {
			return this.getValue<T>(localStorageItem) as T;
		}

		const sessionStorageItem = sessionStorage.getItem(cacheKey);
		if (sessionStorageItem) {
			return this.getValue<T>(sessionStorageItem) as T;
		}

		return null;
	}

	public removeItem(cacheKey: string) {
		if (localStorage.getItem(cacheKey)) {
			localStorage.removeItem(cacheKey);
		}

		if (sessionStorage.getItem(cacheKey)) {
			sessionStorage.removeItem(cacheKey);
		}
	}

	public clear() {
		localStorage.clear();
		sessionStorage.clear();
	}

	public hasItem(cacheKey: string) {
		return Boolean(this.getItem(cacheKey));
	}

	private getValue<T>(value: string): T | string {
		let valueResult: T | string;

		try {
			valueResult = JSON.parse(value) as T;
		} catch (e) {
			valueResult = value;
		}

		return valueResult;
	}
}
