import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { TPAnalysisImpactVectorModel } from '../../models/tpanalysis-impact-vector-model.model';
import { ThirdPartyVectorBreakdownModel } from '../../models/third-party-vector-breakdown-model.model';

@Injectable()
export class TPVectorDataService {
	constructor(private httpService: RQHttpService) {}

	public getById(tpImpactVectorId: string, options = new HttpOptionsViewModel()): Promise<TPAnalysisImpactVectorModel> {
		options.params = this.httpService.getHttpParams({
			tpImpactVectorId
		});

		return this.httpService
			.request<TPAnalysisImpactVectorModel>('get', environment.api + '/api/TPAnalysis/TPVector/GetById', options)
			.toPromise();
	}

	public getVectorBreakdown(tpImpactVectorId: string, options = new HttpOptionsViewModel()): Promise<ThirdPartyVectorBreakdownModel> {
		options.params = this.httpService.getHttpParams({
			tpImpactVectorId
		});

		return this.httpService
			.request<ThirdPartyVectorBreakdownModel>('get', environment.api + '/api/TPAnalysis/TPVector/GetVectorBreakdown', options)
			.toPromise();
	}
}
