import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';

@Injectable()
export class IntegrationJobDataService {
	constructor(private httpService: RQHttpService) {}

	public start(ingestionId: string, options = new HttpOptionsViewModel()): Promise<void> {
		options.params = this.httpService.getHttpParams({
			ingestionId
		});

		return this.httpService.request<void>('get', environment.api + '/api/Integration/IntegrationJob/Start', options).toPromise();
	}

	public stop(ingestionId: string, options = new HttpOptionsViewModel()): Promise<void> {
		options.params = this.httpService.getHttpParams({
			ingestionId
		});

		return this.httpService.request<void>('get', environment.api + '/api/Integration/IntegrationJob/Stop', options).toPromise();
	}

	public reRun(ingestionId: string, options = new HttpOptionsViewModel()): Promise<void> {
		options.params = this.httpService.getHttpParams({
			ingestionId
		});

		return this.httpService.request<void>('get', environment.api + '/api/Integration/IntegrationJob/ReRun', options).toPromise();
	}
}
