import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { ImpactVectorModel } from '../../models/impact-vector-model.model';
import { ImpactVectorGroupFilterModel } from '../../models/impact-vector-group-filter-model.model';
import { BaseImpactVectorModel } from '../../models/base-impact-vector-model.model';
import { Pagination } from '../../models/pagination.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { ImpactVectorFilterDefinitionViewModel } from '../../models/impact-vector-filter-definition-view-model.model';
import { ImpactVectorFilterOptionsModel } from '../../models/impact-vector-filter-options-model.model';
import { ImpactVectorGroupModel } from '../../models/impact-vector-group-model.model';
import { ImpactVectorSummaryModel } from '../../models/impact-vector-summary-model.model';

@Injectable()
export class ImpactVectorLossDataService {
	constructor(private httpService: RQHttpService) {}

	public getGroupedByAttackType(
		analysisId: string,
		filter: ImpactVectorGroupFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<ImpactVectorModel[]> {
		options.params = this.httpService.getHttpParams({
			analysisId,
			filter
		});

		return this.httpService
			.request<ImpactVectorModel[]>('get', environment.api + '/api/Analysis/ImpactVectorLoss/GetGroupedByAttackType', options)
			.toPromise();
	}

	public getByHighestLoss(analysisId: string, count: number, options = new HttpOptionsViewModel()): Promise<BaseImpactVectorModel[]> {
		options.params = this.httpService.getHttpParams({
			analysisId,
			count
		});

		return this.httpService
			.request<BaseImpactVectorModel[]>('get', environment.api + '/api/Analysis/ImpactVectorLoss/GetByHighestLoss', options)
			.toPromise();
	}

	public getById(impactVectorId: string, options = new HttpOptionsViewModel()): Promise<ImpactVectorModel> {
		options.params = this.httpService.getHttpParams({
			impactVectorId
		});

		return this.httpService
			.request<ImpactVectorModel>('get', environment.api + '/api/Analysis/ImpactVectorLoss/GetById', options)
			.toPromise();
	}

	public getByAnalysis(
		analysisId: string,
		pagination: PaginationFilter,
		filter: ImpactVectorFilterDefinitionViewModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<ImpactVectorModel>> {
		options.params = this.httpService.getHttpParams({
			analysisId,
			pagination,
			filter
		});

		return this.httpService
			.request<Pagination<ImpactVectorModel>>('get', environment.api + '/api/Analysis/ImpactVectorLoss/GetByAnalysis', options)
			.toPromise();
	}

	public getBreakdownFilters(analysisId: string, options = new HttpOptionsViewModel()): Promise<ImpactVectorFilterOptionsModel> {
		options.params = this.httpService.getHttpParams({
			analysisId
		});

		return this.httpService
			.request<ImpactVectorFilterOptionsModel>('get', environment.api + '/api/Analysis/ImpactVectorLoss/GetBreakdownFilters', options)
			.toPromise();
	}

	public getBreakdown(
		analysisId: string,
		filter: ImpactVectorFilterDefinitionViewModel,
		options = new HttpOptionsViewModel()
	): Promise<ImpactVectorGroupModel> {
		options.params = this.httpService.getHttpParams({
			analysisId,
			filter
		});

		return this.httpService
			.request<ImpactVectorGroupModel>('get', environment.api + '/api/Analysis/ImpactVectorLoss/GetBreakdown', options)
			.toPromise();
	}

	public getSummary(analysisId: string, options = new HttpOptionsViewModel()): Promise<ImpactVectorSummaryModel> {
		options.params = this.httpService.getHttpParams({
			analysisId
		});

		return this.httpService
			.request<ImpactVectorSummaryModel>('get', environment.api + '/api/Analysis/ImpactVectorLoss/GetSummary', options)
			.toPromise();
	}

	public export(analysisId: string, options = new HttpOptionsViewModel()): Promise<Blob> {
		options.responseType = options.responseType ? options.responseType : 'blob';
		options.params = this.httpService.getHttpParams({
			analysisId
		});

		return this.httpService.request<Blob>('get', environment.api + '/api/Analysis/ImpactVectorLoss/Export', options).toPromise();
	}
}
