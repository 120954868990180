export enum ValidatePasswordResult {
	Failed = 1,
	Success = 0
}

export enum ValidatePasswordStatus {
	ValidPassword = 200,
	RecentPassword = 0,
	CommonPassword = 1,
	InvalidToken = 2,
	DerivativePassword = 7
}
