import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { EnterpriseRecommendationProbabilityReductionModel } from '../../models/enterprise-recommendation-probability-reduction-model.model';
import { Pagination } from '../../models/pagination.model';
import { EnterpriseRecommendationCostModel } from '../../models/enterprise-recommendation-cost-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { EnterpriseRecommendationCostFilterModel } from '../../models/enterprise-recommendation-cost-filter-model.model';
import { EnterpriseRecommendationFilterOptionModel } from '../../models/enterprise-recommendation-filter-option-model.model';
import { EnterpriseApplicationRecommendationsGroupModel } from '../../models/enterprise-application-recommendations-group-model.model';
import { EnterpriseApplicationRecommendationFilterModel } from '../../models/enterprise-application-recommendation-filter-model.model';
import { EnterpriseAnalysisDetailsModel } from '../../models/enterprise-analysis-details-model.model';
import { AnalysisDetailsFilterModel } from '../../models/analysis-details-filter-model.model';
import { RecommendationApplicationDetailsModel } from '../../models/recommendation-application-details-model.model';
import { RecommendationApplicationInputModel } from '../../models/recommendation-application-input-model.model';
import { EnterpriseApplicationRecommendationFilterOptionModel } from '../../models/enterprise-application-recommendation-filter-option-model.model';

@Injectable()
export class EnterpriseRecommendationDataService {
	constructor(private httpService: RQHttpService) {}

	public getTopByLossProbabilityReduction(
		count: number,
		options = new HttpOptionsViewModel()
	): Promise<EnterpriseRecommendationProbabilityReductionModel[]> {
		options.params = this.httpService.getHttpParams({
			count
		});

		return this.httpService
			.request<EnterpriseRecommendationProbabilityReductionModel[]>(
				'get',
				environment.api + '/api/Enterprise/EnterpriseRecommendation/GetTopByLossProbabilityReduction',
				options
			)
			.toPromise();
	}

	public getPaginated(
		paginationModel: PaginationFilter,
		filter: EnterpriseRecommendationCostFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<EnterpriseRecommendationCostModel>> {
		options.params = this.httpService.getHttpParams({
			paginationModel,
			filter
		});

		return this.httpService
			.request<Pagination<EnterpriseRecommendationCostModel>>(
				'get',
				environment.api + '/api/Enterprise/EnterpriseRecommendation/GetPaginated',
				options
			)
			.toPromise();
	}

	public getFilters(options = new HttpOptionsViewModel()): Promise<EnterpriseRecommendationFilterOptionModel> {
		return this.httpService
			.request<EnterpriseRecommendationFilterOptionModel>(
				'get',
				environment.api + '/api/Enterprise/EnterpriseRecommendation/GetFilters',
				options
			)
			.toPromise();
	}

	public getByApplication(
		paginationModel: PaginationFilter,
		filter: EnterpriseApplicationRecommendationFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<EnterpriseApplicationRecommendationsGroupModel>> {
		options.params = this.httpService.getHttpParams({
			paginationModel,
			filter
		});

		return this.httpService
			.request<Pagination<EnterpriseApplicationRecommendationsGroupModel>>(
				'get',
				environment.api + '/api/Enterprise/EnterpriseRecommendation/GetByApplication',
				options
			)
			.toPromise();
	}

	public getAnalysisDetailsView(
		filterModel: AnalysisDetailsFilterModel,
		pagination: PaginationFilter,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<EnterpriseAnalysisDetailsModel>> {
		options.params = this.httpService.getHttpParams({
			filterModel,
			pagination
		});

		return this.httpService
			.request<Pagination<EnterpriseAnalysisDetailsModel>>(
				'get',
				environment.api + '/api/Enterprise/EnterpriseRecommendation/GetAnalysisDetailsView',
				options
			)
			.toPromise();
	}

	public getApplications(
		inputModel: RecommendationApplicationInputModel,
		filterModel: AnalysisDetailsFilterModel,
		pagination: PaginationFilter,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<RecommendationApplicationDetailsModel>> {
		options.params = this.httpService.getHttpParams({
			filterModel,
			pagination
		});

		options.body = inputModel;

		return this.httpService
			.request<Pagination<RecommendationApplicationDetailsModel>>(
				'post',
				environment.api + '/api/Enterprise/EnterpriseRecommendation/GetApplications',
				options
			)
			.toPromise();
	}

	public getByApplicationFilterOptions(
		options = new HttpOptionsViewModel()
	): Promise<EnterpriseApplicationRecommendationFilterOptionModel> {
		return this.httpService
			.request<EnterpriseApplicationRecommendationFilterOptionModel>(
				'get',
				environment.api + '/api/Enterprise/EnterpriseRecommendation/GetByApplicationFilterOptions',
				options
			)
			.toPromise();
	}

	public export(options = new HttpOptionsViewModel()): Promise<Blob> {
		options.responseType = options.responseType ? options.responseType : 'blob';
		return this.httpService.request<Blob>('get', environment.api + '/api/Enterprise/EnterpriseRecommendation/Export', options).toPromise();
	}
}
