import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { TPStartAnalysisModel } from '../../models/tpstart-analysis-model.model';
import { TPAnalysisDone } from '../../models/tpanalysis-done.model';
import { RQAnalysisStatus } from '../../enums/rqanalysis-status.enum';

@Injectable()
export class ThirdPartyAnalysisDataService {
	constructor(private httpService: RQHttpService) {}

	public isAnalysisRunned(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<boolean> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<boolean>('get', environment.api + '/api/TPAnalysis/ThirdPartyAnalysis/IsAnalysisRunned', options)
			.toPromise();
	}

	public startAnalysis(analysisConfig: TPStartAnalysisModel, options = new HttpOptionsViewModel()): Promise<string> {
		options.body = analysisConfig;

		return this.httpService
			.request<string>('post', environment.api + '/api/TPAnalysis/ThirdPartyAnalysis/StartAnalysis', options)
			.toPromise();
	}

	public saveAnalysis(output: TPAnalysisDone, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = output;

		return this.httpService.request<void>('post', environment.api + '/api/TPAnalysis/ThirdPartyAnalysis/SaveAnalysis', options).toPromise();
	}

	public getStatus(thirdPartyAnalysisId: string, options = new HttpOptionsViewModel()): Promise<RQAnalysisStatus> {
		options.params = this.httpService.getHttpParams({
			thirdPartyAnalysisId
		});

		return this.httpService
			.request<RQAnalysisStatus>('post', environment.api + '/api/TPAnalysis/ThirdPartyAnalysis/GetStatus', options)
			.toPromise();
	}
}
