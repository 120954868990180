import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { AnalysisSummaryType } from '../../enums/analysis-summary-type.enum';
import { AnalysisSummaryModel } from '../../models/analysis-summary-model.model';

@Injectable()
export class AnalysisSummaryDataService {
	constructor(private httpService: RQHttpService) {}

	public getSummaryTypeByAnalysisId(analysisId: string, options = new HttpOptionsViewModel()): Promise<AnalysisSummaryType> {
		options.params = this.httpService.getHttpParams({
			analysisId
		});

		return this.httpService
			.request<AnalysisSummaryType>('get', environment.api + '/api/Analysis/AnalysisSummary/GetSummaryTypeByAnalysisId', options)
			.toPromise();
	}

	public getSummaryByAnalysisType(
		analysisType: AnalysisSummaryType,
		options = new HttpOptionsViewModel()
	): Promise<AnalysisSummaryModel[]> {
		options.params = this.httpService.getHttpParams({
			analysisType
		});

		return this.httpService
			.request<AnalysisSummaryModel[]>('get', environment.api + '/api/Analysis/AnalysisSummary/GetSummaryByAnalysisType', options)
			.toPromise();
	}
}
