export class NumberConfig {
	public format?: string;

	public decimals?: number | DecimalConfig;

	public delimiters = {
		thousands: ',',
		decimal: '.'
	};

	public isAbbr?: boolean;

	public abbr = {
		trillion: 'T',
		billion: 'B',
		million: 'M',
		thousand: 'K',
		hundred: '',
		dozens: '',
		digits: ''
	};

	public rounding?: (value: number) => number;
}

export class DecimalConfig {
	[key: string]: number;

	public trillion = 1;

	public billion = 1;

	public million = 1;

	public thousand = 1;

	public hundred = 2;

	public dozens = 2;

	public digits = 2;
}

export class CurrencyConfig extends NumberConfig {
	public symbol = '$';
}

export class PercentConfig extends NumberConfig {
	public symbol = '%';
}
