import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { InstanceType } from '../enums/instance-type.const';
import { RQ_ROUTES } from '../configs/routes.config';

@Injectable({
	providedIn: 'root'
})
export class AuditInterceptorService implements HttpInterceptor {
	constructor(private router: Router) {}

	public intercept(req: HttpRequest<string>, next: HttpHandler): Observable<HttpEvent<string>> {
		if (this.isProOrEnterpriseUrl() && (this.isApiUrl(req) || this.isIdpUrl(req))) {
			req = req.clone({ headers: req.headers.set('InstanceType', this.getInstanceType()) });
		}

		return next.handle(req);
	}

	private isApiUrl(req: HttpRequest<string>) {
		return req.url.includes('/api/') || req.url.toLocaleLowerCase().includes('/identity/');
	}

	private isIdpUrl(req: HttpRequest<string>) {
		return req.url.includes('/idp/') || req.url.toLocaleLowerCase().includes('/identity/');
	}

	private getInstanceType() {
		return this.router.url.includes(RQ_ROUTES.pro.mainPath) ? InstanceType.Pro.toString() : InstanceType.Enterprise.toString();
	}

	private isProOrEnterpriseUrl() {
		return this.router.url && (this.router.url.includes(RQ_ROUTES.pro.mainPath) || this.router.url.includes(RQ_ROUTES.enterprise.path));
	}
}
