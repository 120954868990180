import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { ScenarioDetailsSummaryModel } from '../../models/scenario-details-summary-model.model';
import { ScenarioDetailsSummaryInputModel } from '../../models/scenario-details-summary-input-model.model';

@Injectable()
export class EnterpriseScenarioAnalysisDataService {
	constructor(private httpService: RQHttpService) {}

	public getSummary(
		input: ScenarioDetailsSummaryInputModel,
		legalEntityId: string,
		options = new HttpOptionsViewModel()
	): Promise<ScenarioDetailsSummaryModel[]> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		options.body = input;

		return this.httpService
			.request<ScenarioDetailsSummaryModel[]>('post', environment.api + '/api/Enterprise/EnterpriseScenarioAnalysis/GetSummary', options)
			.toPromise();
	}
}
