import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { NotificationFilterModel } from '../../server/models/notification-filter-model.model';
import { getRouteParams } from '../../core/utils/route.utils';
import { ActivatedRoute } from '@angular/router';
import { PaginationFilter } from '../../server/models/pagination-filter.model';
import { Pagination } from '../../server/models/pagination.model';
import { NotificationViewModel } from '../../server/models/notification-view-model.model';
import { EnterpriseNotificationDataService } from '../../server/services/enterprise/enterprise-notification-data.service';
import { NotificationToastViewModel } from '../models/notification.model';
import { NotificationHubService } from '../../core/hubs/notification-hub.service';
import { NotificationHubModel } from '../models/notification-hub.model';
import { NotificationDataService } from '../../server/services/infrastructure/notification-data.service';
import { Filter } from '../../standalone/components/form-elements/filter/filter.model';

@Injectable({
	providedIn: 'root'
})
export class NotificationService {
	public readonly notificationToast$: Observable<NotificationToastViewModel>;

	public readonly newNotificationCount$: Observable<number>;

	public readonly increaseNotificationCount$: Observable<number>;

	public readonly notificationsData$: Observable<Pagination<NotificationViewModel>>;

	public readonly markAllAsSeen$: Observable<void>;

	private _notificationToast$ = new Subject<NotificationToastViewModel>();

	private _markAllAsSeen$ = new Subject<void>();

	private _newNotificationCount$ = new BehaviorSubject<number>(0);

	private _notificationsData$ = new Subject<Pagination<NotificationViewModel>>();

	private _increaseNotificationCount$ = new Subject<number>();

	constructor(
		private activatedRoute: ActivatedRoute,
		private notificationDataService: NotificationDataService,
		private enterpriseNotificationDataService: EnterpriseNotificationDataService,
		private notificationHubService: NotificationHubService
	) {
		this.newNotificationCount$ = this._newNotificationCount$.asObservable();

		this.increaseNotificationCount$ = this._increaseNotificationCount$.asObservable();

		this.notificationsData$ = this._notificationsData$.asObservable();

		this.markAllAsSeen$ = this._markAllAsSeen$.asObservable();

		this.notificationToast$ = this._notificationToast$.asObservable();
	}

	public async initNotifications() {
		this.connectToNotificationHub();
		await this.countUnseenNotifications();
	}

	public destroyNotifications() {
		this.notificationHubService.disconnect();
	}

	public showToastNotification(notification: NotificationToastViewModel) {
		this._notificationToast$.next(notification);
	}

	public async markAsSeenNotification(id: string) {
		const legalEntityId = getRouteParams(this.activatedRoute.snapshot).legalEntityId as string;
		await this.notificationDataService.markAsSeen(legalEntityId ?? '', id);
		await this.countUnseenNotifications();
	}

	public async markAllAsSeen() {
		const legalEntityId = getRouteParams(this.activatedRoute.snapshot).legalEntityId as string;
		await this.notificationDataService.markAsSeen(legalEntityId ?? '', '');
		this._markAllAsSeen$.next();
		await this.countUnseenNotifications();
	}

	public async loadNotifications(pagination: PaginationFilter) {
		const legalEntityId = getRouteParams(this.activatedRoute.snapshot).legalEntityId as string;
		let data: Pagination<NotificationViewModel>;

		if (legalEntityId) {
			const filter = new NotificationFilterModel();
			filter.legalEntityId = new Filter(legalEntityId);
			data = await this.notificationDataService.getNotifications(filter, pagination);
		} else {
			data = await this.enterpriseNotificationDataService.getNotifications(new NotificationFilterModel(), pagination);
		}

		this._notificationsData$.next(data);
	}

	private async countUnseenNotifications() {
		const notificationFilter = new NotificationFilterModel();
		const legalEntityId = getRouteParams(this.activatedRoute.snapshot).legalEntityId as string;

		if (legalEntityId) {
			notificationFilter.legalEntityId = new Filter(legalEntityId);
		}

		const count = await this.notificationDataService.countUnseenUserNotifications(notificationFilter);
		this._newNotificationCount$.next(count);
	}

	private async connectToNotificationHub() {
		await this.notificationHubService.connect();
		this.notificationHubService.statusChange((scan: NotificationHubModel) => {
			const legalEntityId = getRouteParams(this.activatedRoute.snapshot).legalEntityId as string;
			if (legalEntityId) {
				if (scan.legalEntityId === legalEntityId) {
					this._increaseNotificationCount$.next(scan.count);
				}
			} else {
				this._increaseNotificationCount$.next(scan.count);
			}
		});
	}
}
