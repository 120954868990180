import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { RevenueBusinessEntityUpdateModel } from '../../models/revenue-business-entity-update-model.model';
import { RevenueBusinessEntityFilterModel } from '../../models/revenue-business-entity-filter-model.model';
import { Pagination } from '../../models/pagination.model';
import { RevenueBusinessEntityModel } from '../../models/revenue-business-entity-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { RevenueBusinessEntityFilterOptionModel } from '../../models/revenue-business-entity-filter-option-model.model';

@Injectable()
export class RevenueBusinessEntityDataService {
	constructor(private httpService: RQHttpService) {}

	public update(
		legalEntityId: string,
		assignation: RevenueBusinessEntityUpdateModel,
		filterModel: RevenueBusinessEntityFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<void> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			filterModel
		});

		options.body = assignation;

		return this.httpService.request<void>('post', environment.api + '/api/Configuration/RevenueBusinessEntity/Update', options).toPromise();
	}

	public getPaginated(
		lossExposureId: string,
		pagination: PaginationFilter,
		filter: RevenueBusinessEntityFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<RevenueBusinessEntityModel>> {
		options.params = this.httpService.getHttpParams({
			lossExposureId,
			pagination,
			filter
		});

		return this.httpService
			.request<Pagination<RevenueBusinessEntityModel>>(
				'get',
				environment.api + '/api/Configuration/RevenueBusinessEntity/GetPaginated',
				options
			)
			.toPromise();
	}

	public getFilterOptions(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<RevenueBusinessEntityFilterOptionModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<RevenueBusinessEntityFilterOptionModel>(
				'get',
				environment.api + '/api/Configuration/RevenueBusinessEntity/GetFilterOptions',
				options
			)
			.toPromise();
	}
}
