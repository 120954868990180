import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { authenticatedGuard } from './core/guards/authenticated-guard.service';
import { enterpriseGuard } from './core/guards/enterprise-guard.service';
import { enterpriseReadOnlyGuard } from './core/guards/enterprise-read-only-guard.service';
import { fairOnlyGuard } from './core/guards/fair-only-guard.service';
import { migrationGuard } from './core/guards/migration-guard.service';
import { termsAndAgreementGuard } from './core/guards/terms-and-agreement-guard.service';
import { RQ_ROUTES } from './core/configs/routes.config';
import { PageNotFoundComponent } from './routes/page-not-found/page-not-found.page';
import { fidelityOneGuard } from './core/guards/fidelity-one.guard';
import { controlAnalysisTypeGuard } from './core/guards/control-analysis-type.guard';
import { legalEntityCurrencyResolver } from './core/resolvers/le-currency-resolver.service';
import { hasProAccessGuard } from './core/guards/has-pro-access.guard';

const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		redirectTo: RQ_ROUTES.security.login.url()
	},
	{
		path: RQ_ROUTES.enterprise.path,
		canActivate: [migrationGuard, authenticatedGuard, enterpriseGuard, termsAndAgreementGuard, fairOnlyGuard],
		loadChildren: () => import('./routes/enterprise/enterprise.module').then(m => m.EnterpriseLayoutModule)
	},
	{
		path: RQ_ROUTES.pro.path,
		canActivate: [
			migrationGuard,
			authenticatedGuard,
			hasProAccessGuard,
			controlAnalysisTypeGuard,
			termsAndAgreementGuard,
			enterpriseReadOnlyGuard
		],
		resolve: {
			currency: legalEntityCurrencyResolver
		},
		loadChildren: () => import('./routes/control-analysis/control-analysis.module').then(m => m.ControlAnalysisModule)
	},
	{
		path: RQ_ROUTES.fidelityOne.path,
		canActivate: [migrationGuard, authenticatedGuard, fidelityOneGuard, termsAndAgreementGuard, enterpriseReadOnlyGuard],
		resolve: {
			currency: legalEntityCurrencyResolver
		},
		loadChildren: () => import('./routes/financial-analysis/financial-analysis.module').then(m => m.FinancialAnalysisModule)
	},
	{
		path: RQ_ROUTES.security.path,
		loadChildren: () => import('./routes/security/security.module').then(m => m.SecurityLayoutModule)
	},
	{
		path: RQ_ROUTES.infrastructure.path,
		loadChildren: () => import('./routes/infrastructure/infrastructure.module').then(m => m.InfrastructureLayoutModule)
	},
	{
		path: '**',
		component: PageNotFoundComponent
	}
];

export const routingConfiguration: ExtraOptions = {
	paramsInheritanceStrategy: 'always'
};

@NgModule({
	imports: [RouterModule.forRoot(routes, routingConfiguration)],
	exports: [RouterModule]
})
export class AppRoutingModule {}
