import { LogType } from '../enums/log-type.enum';

export class LogViewModel {
	public message: string;

	public stacktrace?: string;

	public type?: LogType;

	constructor(message: string, type?: LogType, stacktrace?: string) {
		this.message = message;
		this.stacktrace = stacktrace;
		this.type = type;
	}
}
