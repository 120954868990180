export enum ModelTunningMenuType {
	LossVariables = 0,
	AnnualAttackRateOfIncidence = 1,
	CveWeighting = 2,
	FairLossMagnitudeValues = 3,
	LossLimits = 4,
	SemiFairTEF = 5,
	EndpointScoringWeighting = 6,
	QuantitativeScales = 7,
	TechniqueFrequency = 8
}
