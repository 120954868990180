import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { CveBaModel } from '../../models/cve-ba-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';

@Injectable()
export class CveBaDataService {
	constructor(private httpService: RQHttpService) {}

	public getPaginated(
		businessApplicationIds: string[],
		pagination: PaginationFilter,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<CveBaModel>> {
		options.params = this.httpService.getHttpParams({
			pagination
		});

		options.body = businessApplicationIds;

		return this.httpService
			.request<Pagination<CveBaModel>>('post', environment.api + '/api/Configuration/CveBa/GetPaginated', options)
			.toPromise();
	}
}
