import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { RevenueApplicationCharacteristicCreateModel } from '../../models/revenue-application-characteristic-create-model.model';
import { RevenueApplicationCharacteristicUpdateModel } from '../../models/revenue-application-characteristic-update-model.model';

@Injectable()
export class RevenueApplicationCharacteristicDataService {
	constructor(private httpService: RQHttpService) {}

	public create(model: RevenueApplicationCharacteristicCreateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = model;

		return this.httpService
			.request<void>('post', environment.api + '/api/Configuration/RevenueApplicationCharacteristic/Create', options)
			.toPromise();
	}

	public update(model: RevenueApplicationCharacteristicUpdateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = model;

		return this.httpService
			.request<void>('put', environment.api + '/api/Configuration/RevenueApplicationCharacteristic/Update', options)
			.toPromise();
	}
}
