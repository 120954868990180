import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { RecomendationCreateViewModel } from '../../models/recomendation-create-view-model.model';
import { Pagination } from '../../models/pagination.model';
import { RecommendationCostModel } from '../../models/recommendation-cost-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { RecommendationCostFilterModel } from '../../models/recommendation-cost-filter-model.model';
import { RecommendationFilterOptionModel } from '../../models/recommendation-filter-option-model.model';
import { ComputedRoi } from '../../models/computed-roi.model';
import { RecommendationTopByProbabilityReduction } from '../../models/recommendation-top-by-probability-reduction.model';
import { RecommendationTopByExpectedAleReduction } from '../../models/recommendation-top-by-expected-ale-reduction.model';
import { ApplicationRecommendationsViewModel } from '../../models/application-recommendations-view-model.model';
import { AnalysisApplicationRecommendationFilterModel } from '../../models/analysis-application-recommendation-filter-model.model';
import { ApplicationRecommendationFilterOptionsModel } from '../../models/application-recommendation-filter-options-model.model';
import { AnalysisDetailsControlModel } from '../../models/analysis-details-control-model.model';
import { AnalysisDetailsFilterModel } from '../../models/analysis-details-filter-model.model';
import { RecommendationApplicationDetailsModel } from '../../models/recommendation-application-details-model.model';
import { RecommendationApplicationInputModel } from '../../models/recommendation-application-input-model.model';

@Injectable()
export class RecommendationDataService {
	constructor(private httpService: RQHttpService) {}

	public createRange(recommendations: RecomendationCreateViewModel[], options = new HttpOptionsViewModel()): Promise<void> {
		options.body = recommendations;

		return this.httpService.request<void>('post', environment.api + '/api/Analysis/Recommendation/CreateRange', options).toPromise();
	}

	public getByAnalysis(
		analysisId: string,
		paginationModel: PaginationFilter,
		filter: RecommendationCostFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<RecommendationCostModel>> {
		options.params = this.httpService.getHttpParams({
			analysisId,
			paginationModel,
			filter
		});

		return this.httpService
			.request<Pagination<RecommendationCostModel>>('get', environment.api + '/api/Analysis/Recommendation/GetByAnalysis', options)
			.toPromise();
	}

	public getFilterByAnalysis(analysisId: string, options = new HttpOptionsViewModel()): Promise<RecommendationFilterOptionModel> {
		options.params = this.httpService.getHttpParams({
			analysisId
		});

		return this.httpService
			.request<RecommendationFilterOptionModel>('get', environment.api + '/api/Analysis/Recommendation/GetFilterByAnalysis', options)
			.toPromise();
	}

	public computeRoi(expectedLossReduction: number, cost: number, options = new HttpOptionsViewModel()): Promise<ComputedRoi> {
		options.params = this.httpService.getHttpParams({
			expectedLossReduction,
			cost
		});

		return this.httpService.request<ComputedRoi>('get', environment.api + '/api/Analysis/Recommendation/ComputeRoi', options).toPromise();
	}

	public getTopByLossProbabilityReduction(
		analysisId: string,
		count: number,
		options = new HttpOptionsViewModel()
	): Promise<RecommendationTopByProbabilityReduction[]> {
		options.params = this.httpService.getHttpParams({
			analysisId,
			count
		});

		return this.httpService
			.request<RecommendationTopByProbabilityReduction[]>(
				'get',
				environment.api + '/api/Analysis/Recommendation/GetTopByLossProbabilityReduction',
				options
			)
			.toPromise();
	}

	public getTopByExpectedAleReduction(
		analysisId: string,
		count: number,
		options = new HttpOptionsViewModel()
	): Promise<RecommendationTopByExpectedAleReduction[]> {
		options.params = this.httpService.getHttpParams({
			analysisId,
			count
		});

		return this.httpService
			.request<RecommendationTopByExpectedAleReduction[]>(
				'get',
				environment.api + '/api/Analysis/Recommendation/GetTopByExpectedAleReduction',
				options
			)
			.toPromise();
	}

	public getByApplication(
		analysisId: string,
		filterModel: AnalysisApplicationRecommendationFilterModel,
		pagination: PaginationFilter,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<ApplicationRecommendationsViewModel>> {
		options.params = this.httpService.getHttpParams({
			analysisId,
			filterModel,
			pagination
		});

		return this.httpService
			.request<Pagination<ApplicationRecommendationsViewModel>>(
				'get',
				environment.api + '/api/Analysis/Recommendation/GetByApplication',
				options
			)
			.toPromise();
	}

	public getByApplicationFilterOptions(
		analysisId: string,
		options = new HttpOptionsViewModel()
	): Promise<ApplicationRecommendationFilterOptionsModel> {
		options.params = this.httpService.getHttpParams({
			analysisId
		});

		return this.httpService
			.request<ApplicationRecommendationFilterOptionsModel>(
				'get',
				environment.api + '/api/Analysis/Recommendation/GetByApplicationFilterOptions',
				options
			)
			.toPromise();
	}

	public getAnalysisDetailsView(
		analysisId: string,
		filterModel: AnalysisDetailsFilterModel,
		pagination: PaginationFilter,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<AnalysisDetailsControlModel>> {
		options.params = this.httpService.getHttpParams({
			analysisId,
			filterModel,
			pagination
		});

		return this.httpService
			.request<Pagination<AnalysisDetailsControlModel>>(
				'get',
				environment.api + '/api/Analysis/Recommendation/GetAnalysisDetailsView',
				options
			)
			.toPromise();
	}

	public getApplications(
		inputModel: RecommendationApplicationInputModel,
		filterModel: AnalysisDetailsFilterModel,
		pagination: PaginationFilter,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<RecommendationApplicationDetailsModel>> {
		options.params = this.httpService.getHttpParams({
			filterModel,
			pagination
		});

		options.body = inputModel;

		return this.httpService
			.request<Pagination<RecommendationApplicationDetailsModel>>(
				'post',
				environment.api + '/api/Analysis/Recommendation/GetApplications',
				options
			)
			.toPromise();
	}

	public export(analysisId: string, options = new HttpOptionsViewModel()): Promise<Blob> {
		options.responseType = options.responseType ? options.responseType : 'blob';
		options.params = this.httpService.getHttpParams({
			analysisId
		});

		return this.httpService.request<Blob>('get', environment.api + '/api/Analysis/Recommendation/Export', options).toPromise();
	}
}
