import { Injectable } from '@angular/core';
import { ConsoleLoggerModel } from '../models/console-logger.model';
import { Customizer } from './customizer';

@Injectable({
	providedIn: 'root'
})
export class ConsoleLoggerService {
	constructor(private customizer: Customizer) {}

	public log(log: ConsoleLoggerModel) {
		// eslint-disable-next-line no-console
		console.log(log.message, log.stacktrace);
	}

	public logDev(log: ConsoleLoggerModel) {
		if (!this.customizer.isProduction) {
			this.log(log);
		}
	}
}
