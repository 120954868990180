import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { MitreLossBreakdownModel } from '../../models/mitre-loss-breakdown-model.model';

@Injectable()
export class MitreLossImpactDataService {
	constructor(private httpService: RQHttpService) {}

	public getLossBreakdown(scenarioId: string, options = new HttpOptionsViewModel()): Promise<MitreLossBreakdownModel[]> {
		options.params = this.httpService.getHttpParams({
			scenarioId
		});

		return this.httpService
			.request<MitreLossBreakdownModel[]>('get', environment.api + '/api/Scenario/MitreLossImpact/GetLossBreakdown', options)
			.toPromise();
	}
}
