import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpOptionsViewModel } from '../models/http-options.model';

interface ComplexParamType {
	[key: string]: unknown;
}
type PrimitiveType = string | number | boolean | null | undefined;

@Injectable({
	providedIn: 'root'
})
export class RQHttpService {
	constructor(private httpClient: HttpClient) {}

	public request<T>(methodType: string, url: string, options: HttpOptionsViewModel) {
		options.observe = !options.observe ? 'body' : options.observe;

		return this.httpClient.request<T>(methodType, url, {
			body: options.body,
			headers: options.headers,
			observe: options.observe as 'body',
			params: options.params,
			responseType: options.responseType as 'json',
			reportProgress: options.reportProgress,
			withCredentials: options.withCredentials
		});
	}

	public getHttpParams(value: ComplexParamType, httpParams = new HttpParams(), path = '') {
		Object.keys(value).forEach(childPath => {
			const childParam = value[childPath];

			const extendedPath = this.createPath(path, childPath);

			httpParams = this.createFromObject(childParam as ComplexParamType, httpParams, extendedPath);
		});

		return httpParams;
	}

	private createFromArray(childParams: ComplexParamType[], httpParams: HttpParams, path: string) {
		childParams.forEach((param, index) => {
			const extendedPath = this.createPath(path, undefined, index);

			httpParams = this.createFromObject(param, httpParams, extendedPath);
		});

		return httpParams;
	}

	private createFromObject(param: ComplexParamType, httpParams: HttpParams, path: string) {
		if (typeof param === 'object' && param !== null) {
			httpParams = Array.isArray(param) ? this.createFromArray(param, httpParams, path) : this.getHttpParams(param, httpParams, path);
		} else {
			httpParams = this.createFromPrimitive(param, httpParams, path);
		}

		return httpParams;
	}

	private createPath(path: string, childPath?: string, index?: number) {
		let pathResult = '';

		if (path.length === 0 && childPath) {
			pathResult = `${childPath}`;
		}

		if (path.length > 0 && childPath) {
			pathResult = `${path}.${childPath}`;
		}

		if (index !== undefined) {
			pathResult = `${path}[${index}]`;
		}

		return pathResult;
	}

	private createFromPrimitive(value: PrimitiveType, httpParams: HttpParams, path: string) {
		if (typeof value === 'number' || typeof value === 'string' || typeof value === 'boolean') {
			httpParams = httpParams.set(path, value);
		}

		return httpParams;
	}
}
