import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { BaLossValueModel } from '../../models/ba-loss-value-model.model';
import { BaLossValueUpdateModel } from '../../models/ba-loss-value-update-model.model';

@Injectable()
export class BaLossValueDataService {
	constructor(private httpService: RQHttpService) {}

	public get(businessApplicationId: string, options = new HttpOptionsViewModel()): Promise<BaLossValueModel[]> {
		options.params = this.httpService.getHttpParams({
			businessApplicationId
		});

		return this.httpService.request<BaLossValueModel[]>('get', environment.api + '/api/Settings/BaLossValue/Get', options).toPromise();
	}

	public update(lossValue: BaLossValueUpdateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = lossValue;

		return this.httpService.request<void>('post', environment.api + '/api/Settings/BaLossValue/Update', options).toPromise();
	}

	public revertDefaultsByApplicationId(businessApplicationId: string, options = new HttpOptionsViewModel()): Promise<void> {
		options.params = this.httpService.getHttpParams({
			businessApplicationId
		});

		return this.httpService
			.request<void>('get', environment.api + '/api/Settings/BaLossValue/RevertDefaultsByApplicationId', options)
			.toPromise();
	}
}
