import { FormatterType } from '../../../../core/enums/formatter-type.enum';
import { NumberConfig } from '../../../../core/models/number.config';

export class FilterSelectOption<T> {
	constructor(public displayName: string, public value: T, public isSelected = false) {
		if (isSelected) {
			// This block ensures 'isSelected' is referenced to fix the sonar issue
		}
	}
}

export class FilterSelectGroup<T> {
	constructor(public options: Array<FilterSelectOption<T>>, public title?: string) {}
}

export class FilterSearchBoxConfig {
	public searchValue!: string;

	public trigger!: 'change' | 'blur';

	public placeholder!: string;
}

export class FilterConfig {
	public isVisible = true;

	constructor(public name: string, public filter: FilterBase, config?: { isVisible?: boolean }) {
		if (config) {
			this.isVisible = typeof config.isVisible === 'boolean' ? config.isVisible : true;
		}
	}
}

export class FilterOutput {
	[key: string]: Filter<unknown>;
}

export class Filter<T> {
	public value!: T;

	constructor(value?: T, public isSortedAscending?: boolean) {
		if (value !== undefined) {
			this.value = value;
		}
	}
}

export enum FilterType {
	Custom = 1,
	Search = 2,
	Select = 3,
	Range = 4,
	Calendar = 5,
	Numeric = 6,
	Dropdown = 7
}

export class FilterBase {
	public type!: FilterType;

	public displayName!: string;

	public placeholder?: string;

	public value: unknown = null;

	constructor(displayName?: string, placeholder?: string) {
		this.displayName = displayName ? displayName : '';
		this.placeholder = placeholder ? placeholder : 'global_all_label';
	}
}

export class SearchFilter extends FilterBase {
	public type = FilterType.Search;

	constructor(displayName?: string, placeholder?: string) {
		super(displayName, placeholder);
		this.placeholder = placeholder ? placeholder : this.displayName;
	}
}

export class SelectFilter extends FilterBase {
	public type = FilterType.Select;

	public maxSelected!: number;

	public groups: FilterSelectGroup<unknown>[] = [];
}

export class CalendarFilter extends FilterBase {
	public type = FilterType.Calendar;

	public format!: string;

	public datePattern!: string;

	public options!: FilterRangeSelectOption[];
}

export class RangeFilter extends FilterBase {
	public type = FilterType.Range;

	public placeholder = 'global_all_label';

	public groups: FilterSelectGroup<unknown>[] = [];

	public formatter = new FormatterViewModel();

	public isRangeIntervalRounded = true;

	public min!: number;

	public max!: number;
}

export class NumericFilter extends FilterBase {
	public type = FilterType.Numeric;

	public suffix = '';

	public allowNegativeNumbers = false;
}

export class DropdownFilter extends FilterBase {
	public type = FilterType.Dropdown;

	public options?: FilterSelectOption<unknown>[];

	public searchBoxConfig!: FilterSearchBoxConfig;
}

export class FormatterViewModel {
	public type: FormatterType = FormatterType.Number;

	public config!: NumberConfig;
}

export enum FilterRangeCalendarEnum {
	AllTime = 1,
	LastHour = 2,
	Last8Hours = 3,
	LastDay = 4,
	LastWeek = 5,
	LastMonth = 6,
	LastYear = 7,
	Custom = 8
}

export class FilterCalendarValue {
	public occurrence?: FilterRangeCalendarEnum;

	public start?: string;

	public end?: string;
}

export class FilterRangeSelectOption {
	constructor(
		public displayName: string,
		public value: string | number | boolean | null | undefined,
		public timeCallback: () => string | undefined,
		public isSelected = false
	) {
		if (!isSelected) {
			// This hack ensures 'isSelected' is referenced to fix the sonar issue
		}
	}
}

export enum TriggeredBy {
	Checkbox = 1,
	Slider = 2
}

export class FilterRange<T> {
	public values!: T[];

	public range!: { min: number; max: number };

	public trigger!: TriggeredBy;
}
