import { Injectable } from '@angular/core';
import moment, { MomentSetObject } from 'moment';
import { ConsoleLoggerService } from './console-logger.service';
import { Customizer } from './customizer';

@Injectable({
	providedIn: 'root'
})
export class DateFormatterService {
	constructor(private customizer: Customizer, private loggerService: ConsoleLoggerService) {}

	public format(date: string | Date, pattern?: string): string {
		pattern = pattern ? pattern : this.customizer.date.format;
		return this.isValidDate(date) ? moment(date).format(pattern) : date.toString();
	}

	public parse(date: string, pattern?: string): string {
		pattern = pattern ? pattern : this.customizer.date.format;

		if (this.isValidDateByFormat(date, pattern)) {
			date = moment.utc(date, pattern).format();
		} else {
			this.logInvalidDateMessage(date, pattern);
		}

		return date;
	}

	public isValidDateByFormat(date: string, format: string) {
		return moment(date, format).isValid();
	}

	public isValidDate(date: string | Date) {
		return moment(date).isValid();
	}

	public toLocalDate(value: string | Date) {
		return moment(value).locale(window.navigator.language).toDate();
	}

	public setUtcIsoString(objectLiteral: MomentSetObject, { isStartOfDay = false, isEndOfDay = false }) {
		let d = moment().utc().set(objectLiteral);
		if (isStartOfDay) {
			d = d.startOf('day');
		} else if (isEndOfDay) {
			d = d.endOf('day');
		}
		return d.toISOString();
	}

	public isAfter(currentDate: string | Date, targetDate: string | Date, granularity: moment.unitOfTime.StartOf) {
		const current = moment(currentDate);
		const target = moment(targetDate);
		return current.isAfter(target, granularity);
	}

	public isBefore(currentDate: string | Date, targetDate: string | Date, granularity: moment.unitOfTime.StartOf) {
		const current = moment(currentDate);
		const target = moment(targetDate);
		return current.isBefore(target, granularity);
	}

	public isSame(currentDate: string | Date, targetDate: string | Date, granularity: moment.unitOfTime.StartOf) {
		const current = moment(currentDate);
		const target = moment(targetDate);
		return current.isSame(target, granularity);
	}

	public cloneDate(date: string | Date) {
		return moment(date).clone().toDate();
	}

	public add(date: string | Date, amount: number, unit: moment.unitOfTime.DurationConstructor) {
		return moment(date).add(amount, unit).toDate();
	}

	public set(date: string | Date, amount: number, unit: moment.unitOfTime.All) {
		return moment(date).set(unit, amount).toDate();
	}

	public setWithObject(date: string | Date, objectLiteral: MomentSetObject) {
		return moment(date).set(objectLiteral).toDate();
	}

	public subtract(date: string | Date, amount: number, unit: moment.unitOfTime.DurationConstructor) {
		return moment(date).subtract(amount, unit).toDate();
	}

	public startOf(date: string | Date, unit: moment.unitOfTime.StartOf) {
		return moment(date).startOf(unit).toDate();
	}

	public endOf(date: string | Date, unit: moment.unitOfTime.StartOf) {
		return moment(date).endOf(unit).toDate();
	}

	public getWeekDays(date: string | Date) {
		return moment(date).localeData().weekdaysMin();
	}

	public getDaysInMonth(date: string | Date) {
		return moment(date).daysInMonth();
	}

	private logInvalidDateMessage(date: string, pattern: string) {
		const message = pattern
			? `Date with value: '${date}' and format: '${pattern} is not valid'`
			: `Date with value: '${date}' is not valid'`;

		this.loggerService.logDev({ message });
	}
}
