import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { IdentityService } from '../../shared/services/identity.service';
import { RQ_ROUTES } from '../configs/routes.config';

export const authenticatedGuard = async (): Promise<boolean> => {
	const router = inject(Router);
	const identityService = inject(IdentityService);

	await identityService.load();

	if (!identityService.isAuthenticated) {
		router.navigateByUrl(RQ_ROUTES.security.login.url());

		return false;
	} else {
		return true;
	}
};
