export function isNullOrUndefined(value: unknown): boolean {
	return value === null || value === undefined;
}

export function isNumber(value: unknown): boolean {
	return typeof value === 'number';
}

export function isEmptyString(value: unknown) {
	return typeof value === 'string' && value.length === 0;
}

export function clone<T>(value: T) {
	return isNullOrUndefined(value) ? value : (JSON.parse(JSON.stringify(value)) as T);
}

export function generateUUID() {
	let d = new Date().getTime();

	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		const r = (d + Math.random() * 16) % 16 | 0;
		d = Math.floor(d / 16);
		return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
	});
}
