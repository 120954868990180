import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { InstanceModel } from '../../models/instance-model.model';

@Injectable()
export class EnterpriseCurrencyDataService {
	constructor(private httpService: RQHttpService) {}

	public get(options = new HttpOptionsViewModel()): Promise<InstanceModel> {
		return this.httpService.request<InstanceModel>('get', environment.api + '/api/Enterprise/EnterpriseCurrency/Get', options).toPromise();
	}

	public update(currencyId: string, options = new HttpOptionsViewModel()): Promise<void> {
		options.params = this.httpService.getHttpParams({
			currencyId
		});

		return this.httpService.request<void>('put', environment.api + '/api/Enterprise/EnterpriseCurrency/Update', options).toPromise();
	}
}
