import { BaseComponent } from '../../../shared/components/base.component';
import { animate, sequence, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { NotificationService } from '../../../shared/services/notification.service';
import { NotificationType } from '../../../core/enums/notification-type.enum';
import { NotificationToastViewModel } from '../../../shared/models/notification.model';
import { TranslationService } from '../../../core/services/translation.service';
import { IconComponent } from '../icon/icon.component';
import { CommonModule } from '@angular/common';

export const notificationHorizontalOffset = 'translateX(100%)';

@Component({
	standalone: true,
	selector: 'rq-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss'],
	imports: [CommonModule, IconComponent],
	animations: [
		trigger('notificationEnterLeave', [
			transition('topRight => void', [
				sequence([
					animate(
						'250ms ease',
						style({
							height: '*',
							opacity: 0.2,
							transform: notificationHorizontalOffset
						})
					),
					animate(
						'100ms ease',
						style({
							height: 0,
							padding: 0,
							opacity: 0
						})
					)
				])
			]),
			transition('void => topRight', [
				style({
					height: 0,
					opacity: 0,
					transform: notificationHorizontalOffset
				}),
				sequence([
					animate(
						'100ms ease',
						style({
							height: '*',
							opacity: 0.2,
							transform: notificationHorizontalOffset
						})
					),
					animate(
						'350ms ease',
						style({
							height: '*',
							opacity: 1,
							transform: 'translateX(0)'
						})
					)
				])
			])
		])
	]
})
export class NotificationComponent extends BaseComponent implements OnInit {
	public notifications: NotificationToastViewModel[] = [];

	public notificationType = NotificationType;

	constructor(private notificationService: NotificationService, private translateService: TranslationService) {
		super();
	}

	public ngOnInit() {
		this.subscribeToEvents();
	}

	public dismissNotification(notification: NotificationToastViewModel) {
		this.notifications.forEach((n, index) => {
			if (notification === n || (notification.analysisId && n.analysisId && notification.analysisId === n.analysisId)) {
				notification.dismissed = true;

				this.notifications.splice(index, 1);
			}
		});
	}

	private subscribeToEvents() {
		this.subscriptions.push(
			this.notificationService.notificationToast$.subscribe((notification: NotificationToastViewModel) => {
				const duplicateNotification = this.notifications.find(n => n.title === this.translateService.get(notification.title));
				if (!duplicateNotification || (environment.production && this.notifications.length === 0 && !notification.dismissed)) {
					this.notifications.unshift(notification);
				}

				if (notification?.dismissed) {
					this.dismissNotification(notification);
				}
				if (notification?.timer) {
					setTimeout(() => {
						this.dismissNotification(notification);
					}, notification.timer);
				}
			})
		);
	}
}
