export class ConsoleLoggerModel {
	public message: string;

	public stacktrace?: string;

	constructor(message: string, stacktrace?: string) {
		this.message = message;
		this.stacktrace = stacktrace;
	}
}
