import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { ScenarioMitreModel } from '../../models/scenario-mitre-model.model';
import { IActionResult } from '../../models/iaction-result.model';
import { ScenarioMitreUpdateModel } from '../../models/scenario-mitre-update-model.model';
import { ScenarioMitreBaseModel } from '../../models/scenario-mitre-base-model.model';

@Injectable()
export class ScenarioMitreDataService {
	constructor(private httpService: RQHttpService) {}

	public getByScenarioId(scenarioId: string, options = new HttpOptionsViewModel()): Promise<ScenarioMitreModel> {
		options.params = this.httpService.getHttpParams({
			scenarioId
		});

		return this.httpService
			.request<ScenarioMitreModel>('get', environment.api + '/api/Scenario/ScenarioMitre/GetByScenarioId', options)
			.toPromise();
	}

	public update(updateModel: ScenarioMitreUpdateModel, options = new HttpOptionsViewModel()): Promise<IActionResult> {
		options.body = updateModel;

		return this.httpService.request<IActionResult>('put', environment.api + '/api/Scenario/ScenarioMitre/Update', options).toPromise();
	}

	public revertToDefault(scenarioId: string, options = new HttpOptionsViewModel()): Promise<ScenarioMitreBaseModel> {
		options.params = this.httpService.getHttpParams({
			scenarioId
		});

		return this.httpService
			.request<ScenarioMitreBaseModel>('put', environment.api + '/api/Scenario/ScenarioMitre/RevertToDefault', options)
			.toPromise();
	}

	public clone(
		clonedScenarioId: string,
		legalEntityId: string,
		newScenarioName: string,
		options = new HttpOptionsViewModel()
	): Promise<IActionResult> {
		options.params = this.httpService.getHttpParams({
			clonedScenarioId,
			legalEntityId,
			newScenarioName
		});

		return this.httpService.request<IActionResult>('post', environment.api + '/api/Scenario/ScenarioMitre/Clone', options).toPromise();
	}
}
