import { HttpHeaders, HttpParams } from '@angular/common/http';

export class HttpOptionsViewModel {
	public body?: unknown;

	public loading?: LoadingViewModel;

	public headers?:
		| HttpHeaders
		| {
				[header: string]: string | string[];
		  };

	public observe?: 'body' | 'events' | 'response';

	public params?:
		| HttpParams
		| {
				[param: string]: string | string[];
		  };

	public reportProgress?: boolean;

	public responseType?: 'arrayBuffer' | 'blob' | 'text' | 'json';

	public withCredentials?: boolean;
}

export class LoadingViewModel {
	public id?: string;

	public selectors?: Array<string>;

	public type?: LoadingType;

	public isActive?: boolean;

	public state?: LoadingStateViewModel;
}

export class LoadingStateViewModel {
	public selector!: string;

	public element!: HTMLElement;

	public parent!: HTMLElement;
}

export enum LoadingType {
	None = 0,
	Default = 1,
	Global = 2,
	Selector = 3
}
