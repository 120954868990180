import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class HttpParserService {
	public getError(response: HttpErrorResponse) {
		let errors: string[] = [];

		if (response.error) {
			const error = (
				typeof response.error === 'string' && this.isValidJson(response.error) ? JSON.parse(response.error) : response.error
			) as Array<string> | string | { [key: string]: string };

			if (error instanceof Array) {
				errors = this.parseArrayErrors(error);
			} else if (typeof error === 'object') {
				errors = this.parseObjectErrors(error);
			} else if (this.isValidString(error)) {
				errors = [error];
			}
		}

		if (response.message) {
			errors.push(response.message);
		}

		let errorResult = '';

		errors.forEach(error => (errorResult += '\n\n\n' + error));

		return errorResult;
	}

	private parseArrayErrors(httpErrors: Array<string>) {
		const errors: string[] = [];

		httpErrors.forEach(error => {
			if (this.isValidString(error)) {
				errors.push(error);
			}
		});

		return errors;
	}

	private parseObjectErrors(httpErrors: { [key: string]: string }) {
		const errors = [];

		for (const key in httpErrors) {
			if (httpErrors.hasOwnProperty(key)) {
				const error = httpErrors[key];

				if (this.isValidString(error)) {
					errors.push(error);
				}
			}
		}

		return errors;
	}

	private isValidJson(value: string) {
		let isValidJsonResult = true;

		try {
			JSON.parse(value);
		} catch (error) {
			isValidJsonResult = false;
		}

		return isValidJsonResult;
	}

	private isValidString(error: string) {
		return typeof error === 'string' && error !== '';
	}
}
