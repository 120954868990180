import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { FairAnalysisInputModel } from '../../models/fair-analysis-input-model.model';

@Injectable()
export class FairAnalysisInputDataService {
	constructor(private httpService: RQHttpService) {}

	public getByScenarioId(scenarioId: string, options = new HttpOptionsViewModel()): Promise<FairAnalysisInputModel[]> {
		options.params = this.httpService.getHttpParams({
			scenarioId
		});

		return this.httpService
			.request<FairAnalysisInputModel[]>('get', environment.api + '/api/Fair/FairAnalysisInput/GetByScenarioId', options)
			.toPromise();
	}
}
