import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { ActionResult } from '../../models/action-result.model';
import { QualitativeScaleUpdateModel } from '../../models/qualitative-scale-update-model.model';
import { QualitativeScaleModel } from '../../models/qualitative-scale-model.model';

@Injectable()
export class QualitativeScaleDataService {
	constructor(private httpService: RQHttpService) {}

	public update(updateModels: QualitativeScaleUpdateModel[], options = new HttpOptionsViewModel()): Promise<ActionResult> {
		options.body = updateModels;

		return this.httpService
			.request<ActionResult>('put', environment.api + '/api/Configuration/QualitativeScale/Update', options)
			.toPromise();
	}

	public getByLegalEntityId(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<QualitativeScaleModel[]> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<QualitativeScaleModel[]>('get', environment.api + '/api/Configuration/QualitativeScale/GetByLegalEntityId', options)
			.toPromise();
	}

	public getByName(legalEntityId: string, name: string, options = new HttpOptionsViewModel()): Promise<QualitativeScaleModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			name
		});

		return this.httpService
			.request<QualitativeScaleModel>('get', environment.api + '/api/Configuration/QualitativeScale/GetByName', options)
			.toPromise();
	}
}
