import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { LogoType } from '../../enums/logo-type.enum';

@Injectable()
export class LogoDataService {
	constructor(private httpService: RQHttpService) {}

	public get(logoType: LogoType, options = new HttpOptionsViewModel()): Promise<Blob> {
		options.responseType = options.responseType ? options.responseType : 'blob';
		options.params = this.httpService.getHttpParams({
			logoType
		});

		return this.httpService.request<Blob>('get', environment.api + '/api/Infrastructure/Logo/Get', options).toPromise();
	}
}
