export const SECURITY_ROUTE = {
	path: 'security',
	url: securityUrl,
	login: {
		path: 'login',
		url: loginUrl
	},
	forgotPassword: {
		path: 'forgot-password',
		url: forgotPasswordUrl
	},
	resetPassword: {
		path: 'set-password',
		url: resetPasswordUrl
	},
	unlock: {
		path: 'unlock-account',
		url: unlockUrl
	}
};

export function securityUrl() {
	return `/${SECURITY_ROUTE.path}`;
}

export function forgotPasswordUrl() {
	return `${securityUrl()}/${SECURITY_ROUTE.forgotPassword.path}`;
}

export function loginUrl() {
	return `${securityUrl()}/${SECURITY_ROUTE.login.path}`;
}

export function resetPasswordUrl() {
	return `${securityUrl()}/${SECURITY_ROUTE.resetPassword.path}`;
}

export function unlockUrl() {
	return `${securityUrl()}/${SECURITY_ROUTE.unlock.path}`;
}
