import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { MetadataModel } from '../../models/metadata-model.model';

@Injectable()
export class MetadataDataService {
	constructor(private httpService: RQHttpService) {}

	public get(options = new HttpOptionsViewModel()): Promise<MetadataModel> {
		return this.httpService.request<MetadataModel>('get', environment.api + '/api/Infrastructure/Metadata/Get', options).toPromise();
	}
}
