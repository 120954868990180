import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { ScenarioDefinitionModel } from '../../models/scenario-definition-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { ScenarioFilterViewModel } from '../../models/scenario-filter-view-model.model';
import { ScenarioBaseModel } from '../../models/scenario-base-model.model';
import { ScenarioBaseFilterViewModel } from '../../models/scenario-base-filter-view-model.model';
import { ScenarioCreateModel } from '../../models/scenario-create-model.model';
import { ScenarioUpdateModel } from '../../models/scenario-update-model.model';
import { ScenarioModel } from '../../models/scenario-model.model';
import { ScenarioFilterOptionModel } from '../../models/scenario-filter-option-model.model';
import { IActionResult } from '../../models/iaction-result.model';
import { ScenarioApplicationBaseModel } from '../../models/scenario-application-base-model.model';

@Injectable()
export class ScenarioDataService {
	constructor(private httpService: RQHttpService) {}

	public getScenarios(
		legalEntityId: string,
		pagination: PaginationFilter,
		filter: ScenarioFilterViewModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<ScenarioDefinitionModel>> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			pagination,
			filter
		});

		return this.httpService
			.request<Pagination<ScenarioDefinitionModel>>('get', environment.api + '/api/Scenario/Scenario/GetScenarios', options)
			.toPromise();
	}

	public getAllByFilter(
		legalEntityId: string,
		filter: ScenarioBaseFilterViewModel,
		options = new HttpOptionsViewModel()
	): Promise<ScenarioBaseModel[]> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			filter
		});

		return this.httpService
			.request<ScenarioBaseModel[]>('get', environment.api + '/api/Scenario/Scenario/GetAllByFilter', options)
			.toPromise();
	}

	public create(scenario: ScenarioCreateModel, options = new HttpOptionsViewModel()): Promise<string> {
		options.body = scenario;

		return this.httpService.request<string>('post', environment.api + '/api/Scenario/Scenario/Create', options).toPromise();
	}

	public update(scenario: ScenarioUpdateModel, options = new HttpOptionsViewModel()): Promise<string> {
		options.body = scenario;

		return this.httpService.request<string>('put', environment.api + '/api/Scenario/Scenario/Update', options).toPromise();
	}

	public getById(scenarioId: string, options = new HttpOptionsViewModel()): Promise<ScenarioModel> {
		options.params = this.httpService.getHttpParams({
			scenarioId
		});

		return this.httpService.request<ScenarioModel>('get', environment.api + '/api/Scenario/Scenario/GetById', options).toPromise();
	}

	public getByProviderId(providerId: string, options = new HttpOptionsViewModel()): Promise<ScenarioModel> {
		options.params = this.httpService.getHttpParams({
			providerId
		});

		return this.httpService.request<ScenarioModel>('get', environment.api + '/api/Scenario/Scenario/GetByProviderId', options).toPromise();
	}

	public getScenarioFilterOptions(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<ScenarioFilterOptionModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<ScenarioFilterOptionModel>('get', environment.api + '/api/Scenario/Scenario/GetScenarioFilterOptions', options)
			.toPromise();
	}

	public delete(scenarioId: string, options = new HttpOptionsViewModel()): Promise<IActionResult> {
		options.params = this.httpService.getHttpParams({
			scenarioId
		});

		return this.httpService.request<IActionResult>('delete', environment.api + '/api/Scenario/Scenario/Delete', options).toPromise();
	}

	public getAllApplications(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<ScenarioApplicationBaseModel[]> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<ScenarioApplicationBaseModel[]>('get', environment.api + '/api/Scenario/Scenario/GetAllApplications', options)
			.toPromise();
	}

	public getByName(legalEntityId: string, scenarioName: string, options = new HttpOptionsViewModel()): Promise<ScenarioModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			scenarioName
		});

		return this.httpService.request<ScenarioModel>('get', environment.api + '/api/Scenario/Scenario/GetByName', options).toPromise();
	}

	public export(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<Blob> {
		options.responseType = options.responseType ? options.responseType : 'blob';
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService.request<Blob>('get', environment.api + '/api/Scenario/Scenario/Export', options).toPromise();
	}
}
