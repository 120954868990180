import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { IntegrationSchedulerSettingModel } from '../../models/integration-scheduler-setting-model.model';

@Injectable()
export class IntegrationSchedulerSettingsDataService {
	constructor(private httpService: RQHttpService) {}

	public getById(integrationId: string, options = new HttpOptionsViewModel()): Promise<IntegrationSchedulerSettingModel> {
		options.params = this.httpService.getHttpParams({
			integrationId
		});

		return this.httpService
			.request<IntegrationSchedulerSettingModel>('get', environment.api + '/api/Integration/IntegrationSchedulerSettings/GetById', options)
			.toPromise();
	}

	public create(model: IntegrationSchedulerSettingModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = model;

		return this.httpService
			.request<void>('post', environment.api + '/api/Integration/IntegrationSchedulerSettings/Create', options)
			.toPromise();
	}

	public update(model: IntegrationSchedulerSettingModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = model;

		return this.httpService
			.request<void>('post', environment.api + '/api/Integration/IntegrationSchedulerSettings/Update', options)
			.toPromise();
	}
}
