export enum LossExposureEditWizardStep {
	Overview = 1,
	ControlSetup = 2,
	Summary = 3
}

export enum LossExposureDataEditWizardStep {
	Overview = 1,
	DataSetup = 2,
	Summary = 3
}

export enum SemiAutomatedFairEditWizardStep {
	Overview = 1,
	TEFSetup = 2,
	PrimaryLoss = 3,
	Summary = 4
}

export enum LossExposureRevenueEditWizardStep {
	Overview = 1,
	Revenue = 2,
	ApplicationType = 3,
	ApplicationCharacteristics = 4,
	Summary = 5
}
