export enum LoggerType {
	Console = 0,
	Frontend = 1,
	Api = 2
}

export enum LogType {
	Error = 0,
	Warning = 1,
	Info = 2
}
