import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { EnterpriseConfigurationSettingModel } from '../../models/enterprise-configuration-setting-model.model';
import { Control } from '../../enums/control.enum';

@Injectable()
export class ConfigurationManagementDataService {
	constructor(private httpService: RQHttpService) {}

	public getByControlName(control: Control, options = new HttpOptionsViewModel()): Promise<EnterpriseConfigurationSettingModel> {
		options.params = this.httpService.getHttpParams({
			control
		});

		return this.httpService
			.request<EnterpriseConfigurationSettingModel>(
				'get',
				environment.api + '/api/Enterprise/ConfigurationManagement/GetByControlName',
				options
			)
			.toPromise();
	}

	public update(configurationSettings: EnterpriseConfigurationSettingModel[], options = new HttpOptionsViewModel()): Promise<void> {
		options.body = configurationSettings;

		return this.httpService.request<void>('post', environment.api + '/api/Enterprise/ConfigurationManagement/Update', options).toPromise();
	}
}
