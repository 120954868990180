import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { ExploitabilityUpdateViewModel } from '../../models/exploitability-update-view-model.model';

@Injectable()
export class ExploitabilityDataService {
	constructor(private httpService: RQHttpService) {}

	public updateRange(viewModels: ExploitabilityUpdateViewModel[], options = new HttpOptionsViewModel()): Promise<void> {
		options.body = viewModels;

		return this.httpService.request<void>('post', environment.api + '/api/Configuration/Exploitability/UpdateRange', options).toPromise();
	}

	public reconciliationCleanup(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<void> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<void>('post', environment.api + '/api/Configuration/Exploitability/ReconciliationCleanup', options)
			.toPromise();
	}
}
