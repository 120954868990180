import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';

@Injectable()
export class ScenarioAnalysisMcpCsvDataService {
	constructor(private httpService: RQHttpService) {}

	public exportRecommendations(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<Blob> {
		options.responseType = options.responseType ? options.responseType : 'blob';
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<Blob>('get', environment.api + '/api/Scenario/ScenarioAnalysisMcpCsv/ExportRecommendations', options)
			.toPromise();
	}
}
