import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { NotificationViewModel } from '../../models/notification-view-model.model';
import { NotificationFilterModel } from '../../models/notification-filter-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { NotificationDetailedModel } from '../../models/notification-detailed-model.model';

@Injectable()
export class NotificationDataService {
	constructor(private httpService: RQHttpService) {}

	public getNotifications(
		filter: NotificationFilterModel,
		pagination: PaginationFilter,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<NotificationViewModel>> {
		options.params = this.httpService.getHttpParams({
			filter,
			pagination
		});

		return this.httpService
			.request<Pagination<NotificationViewModel>>('get', environment.api + '/api/Infrastructure/Notification/GetNotifications', options)
			.toPromise();
	}

	public getDetailedNotifications(
		filter: NotificationFilterModel,
		pagination: PaginationFilter,
		options = new HttpOptionsViewModel()
	): Promise<NotificationDetailedModel> {
		options.params = this.httpService.getHttpParams({
			filter,
			pagination
		});

		return this.httpService
			.request<NotificationDetailedModel>('get', environment.api + '/api/Infrastructure/Notification/GetDetailedNotifications', options)
			.toPromise();
	}

	public countUnseenUserNotifications(filter: NotificationFilterModel, options = new HttpOptionsViewModel()): Promise<number> {
		options.params = this.httpService.getHttpParams({
			filter
		});

		return this.httpService
			.request<number>('get', environment.api + '/api/Infrastructure/Notification/CountUnseenUserNotifications', options)
			.toPromise();
	}

	public markAsSeen(legalEntityId: string, notificationId: string, options = new HttpOptionsViewModel()): Promise<void> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			notificationId
		});

		return this.httpService.request<void>('post', environment.api + '/api/Infrastructure/Notification/MarkAsSeen', options).toPromise();
	}

	public export(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<Blob> {
		options.responseType = options.responseType ? options.responseType : 'blob';
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService.request<Blob>('get', environment.api + '/api/Infrastructure/Notification/Export', options).toPromise();
	}
}
