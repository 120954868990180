import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { ScenarioMcpRecommendationSummaryViewModel } from '../../models/scenario-mcp-recommendation-summary-view-model.model';

@Injectable()
export class ScenarioAnalysisMcpRecommendationDataService {
	constructor(private httpService: RQHttpService) {}

	public getAnalyses(scenarioId: string, options = new HttpOptionsViewModel()): Promise<ScenarioMcpRecommendationSummaryViewModel> {
		options.params = this.httpService.getHttpParams({
			scenarioId
		});

		return this.httpService
			.request<ScenarioMcpRecommendationSummaryViewModel>(
				'get',
				environment.api + '/api/Scenario/ScenarioAnalysisMcpRecommendation/GetAnalyses',
				options
			)
			.toPromise();
	}
}
