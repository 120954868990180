import { baseVariables } from './base.variables';
import { defaultThemeColors } from './themes-colors/default.theme';
import { ThemeKeys } from './theme-keys.enum';
import { ITheme } from './theme.interface';

const defaultTheme: ITheme = {
	...baseVariables,
	...defaultThemeColors
};

export const THEMES: Record<ThemeKeys, ITheme> = {
	[ThemeKeys.Default]: defaultTheme
};
