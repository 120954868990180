import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { ApplicationLegalEntityMigrationModel } from '../../models/application-legal-entity-migration-model.model';

@Injectable()
export class ApplicationLegalEntityMigrationDataService {
	constructor(private httpService: RQHttpService) {}

	public getByLegalEntityId(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<ApplicationLegalEntityMigrationModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<ApplicationLegalEntityMigrationModel>(
				'get',
				environment.api + '/api/Infrastructure/ApplicationLegalEntityMigration/GetByLegalEntityId',
				options
			)
			.toPromise();
	}

	public getAll(options = new HttpOptionsViewModel()): Promise<ApplicationLegalEntityMigrationModel[]> {
		return this.httpService
			.request<ApplicationLegalEntityMigrationModel[]>(
				'get',
				environment.api + '/api/Infrastructure/ApplicationLegalEntityMigration/GetAll',
				options
			)
			.toPromise();
	}
}
