import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { AnalysisCveScoreWeightingViewModel } from '../../models/analysis-cve-score-weighting-view-model.model';

@Injectable()
export class AnalysisCveScoreWeightingDataService {
	constructor(private httpService: RQHttpService) {}

	public getByAnalysisId(analysisId: string, options = new HttpOptionsViewModel()): Promise<AnalysisCveScoreWeightingViewModel[]> {
		options.params = this.httpService.getHttpParams({
			analysisId
		});

		return this.httpService
			.request<AnalysisCveScoreWeightingViewModel[]>(
				'get',
				environment.api + '/api/Analysis/AnalysisCveScoreWeighting/GetByAnalysisId',
				options
			)
			.toPromise();
	}
}
