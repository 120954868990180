import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { CveTechnicalWeightingModel } from '../../models/cve-technical-weighting-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { CveTechnicalWeightFilterModel } from '../../models/cve-technical-weight-filter-model.model';
import { CveTechnicalWeightFilterOptionsModel } from '../../models/cve-technical-weight-filter-options-model.model';

@Injectable()
export class CveTechnicalWeightingDataService {
	constructor(private httpService: RQHttpService) {}

	public getByLegalEntity(
		legalEntityId: string,
		paginator: PaginationFilter,
		filter: CveTechnicalWeightFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<CveTechnicalWeightingModel>> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			paginator,
			filter
		});

		return this.httpService
			.request<Pagination<CveTechnicalWeightingModel>>(
				'get',
				environment.api + '/api/Settings/CveTechnicalWeighting/GetByLegalEntity',
				options
			)
			.toPromise();
	}

	public getFilterByLegalEntity(
		legalEntityId: string,
		options = new HttpOptionsViewModel()
	): Promise<CveTechnicalWeightFilterOptionsModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<CveTechnicalWeightFilterOptionsModel>(
				'get',
				environment.api + '/api/Settings/CveTechnicalWeighting/GetFilterByLegalEntity',
				options
			)
			.toPromise();
	}

	public update(technicalWeighting: CveTechnicalWeightingModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = technicalWeighting;

		return this.httpService.request<void>('put', environment.api + '/api/Settings/CveTechnicalWeighting/Update', options).toPromise();
	}
}
