import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { EnterpriseNotificationViewModel } from '../../models/enterprise-notification-view-model.model';
import { NotificationFilterModel } from '../../models/notification-filter-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';

@Injectable()
export class EnterpriseNotificationDataService {
	constructor(private httpService: RQHttpService) {}

	public getNotifications(
		filter: NotificationFilterModel,
		pagination: PaginationFilter,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<EnterpriseNotificationViewModel>> {
		options.params = this.httpService.getHttpParams({
			filter,
			pagination
		});

		return this.httpService
			.request<Pagination<EnterpriseNotificationViewModel>>(
				'get',
				environment.api + '/api/Enterprise/EnterpriseNotification/GetNotifications',
				options
			)
			.toPromise();
	}

	public export(options = new HttpOptionsViewModel()): Promise<Blob> {
		options.responseType = options.responseType ? options.responseType : 'blob';
		return this.httpService.request<Blob>('get', environment.api + '/api/Enterprise/EnterpriseNotification/Export', options).toPromise();
	}
}
