import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationDataService } from '../../server/services/identity/authorization-data.service';
import { RQ_ROUTES } from '../configs/routes.config';

export const enterpriseReadOnlyGuard = async (): Promise<boolean> => {
	const router = inject(Router);
	const authorizationDataService = inject(AuthorizationDataService);

	const isReadOnly = await authorizationDataService.hasEnterpriseReadOnlyAccessRights();

	if (isReadOnly) {
		await router.navigateByUrl(RQ_ROUTES.infrastructure.pageNotFound.url);
	}

	return !isReadOnly;
};
