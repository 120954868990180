import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { BusinessApplicationSummaryModel } from '../../models/business-application-summary-model.model';
import { BusinessApplicationFilterModel } from '../../models/business-application-filter-model.model';
import { LossModel } from '../../models/loss-model.model';
import { LossSubType } from '../../enums/loss-sub-type.enum';
import { PaginationSelection } from '../../models/pagination-selection.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { ApplicationAnalysisFilterModel } from '../../models/application-analysis-filter-model.model';
import { AnalysisBusinessApplicationFilterOptionModel } from '../../models/analysis-business-application-filter-option-model.model';
import { Pagination } from '../../models/pagination.model';
import { AnalysisApplicationRiskModel } from '../../models/analysis-application-risk-model.model';
import { AnalysisApplicationRiskFilterModel } from '../../models/analysis-application-risk-filter-model.model';
import { AnalysisApplicationRiskFilterOptionModel } from '../../models/analysis-application-risk-filter-option-model.model';
import { AnalysisApplicationRiskBaseModel } from '../../models/analysis-application-risk-base-model.model';
import { AnalysisApplicationModel } from '../../models/analysis-application-model.model';
import { ImpactVectorRiskModel } from '../../models/impact-vector-risk-model.model';

@Injectable()
export class BusinessApplicationLossDataService {
	constructor(private httpService: RQHttpService) {}

	public getByAnalysis(
		analysisId: string,
		filter: BusinessApplicationFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<BusinessApplicationSummaryModel[]> {
		options.params = this.httpService.getHttpParams({
			analysisId,
			filter
		});

		return this.httpService
			.request<BusinessApplicationSummaryModel[]>('get', environment.api + '/api/Analysis/BusinessApplicationLoss/GetByAnalysis', options)
			.toPromise();
	}

	public getLossGroupedBySubType(
		analysisId: string,
		analysisBusinessApplicationId: string,
		options = new HttpOptionsViewModel()
	): Promise<LossModel<LossSubType>[]> {
		options.params = this.httpService.getHttpParams({
			analysisId,
			analysisBusinessApplicationId
		});

		return this.httpService
			.request<LossModel<LossSubType>[]>('get', environment.api + '/api/Analysis/BusinessApplicationLoss/GetLossGroupedBySubType', options)
			.toPromise();
	}

	public getPaginatedByAnalysis(
		analysisId: string,
		pagination: PaginationFilter,
		filter: ApplicationAnalysisFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<PaginationSelection<BusinessApplicationSummaryModel>> {
		options.params = this.httpService.getHttpParams({
			analysisId,
			pagination,
			filter
		});

		return this.httpService
			.request<PaginationSelection<BusinessApplicationSummaryModel>>(
				'get',
				environment.api + '/api/Analysis/BusinessApplicationLoss/GetPaginatedByAnalysis',
				options
			)
			.toPromise();
	}

	public getFilterOptions(analysisId: string, options = new HttpOptionsViewModel()): Promise<AnalysisBusinessApplicationFilterOptionModel> {
		options.params = this.httpService.getHttpParams({
			analysisId
		});

		return this.httpService
			.request<AnalysisBusinessApplicationFilterOptionModel>(
				'get',
				environment.api + '/api/Analysis/BusinessApplicationLoss/GetFilterOptions',
				options
			)
			.toPromise();
	}

	public getPaginatedRisk(
		analysisId: string,
		filter: AnalysisApplicationRiskFilterModel,
		pagination: PaginationFilter,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<AnalysisApplicationRiskModel>> {
		options.params = this.httpService.getHttpParams({
			analysisId,
			filter,
			pagination
		});

		return this.httpService
			.request<Pagination<AnalysisApplicationRiskModel>>(
				'get',
				environment.api + '/api/Analysis/BusinessApplicationLoss/GetPaginatedRisk',
				options
			)
			.toPromise();
	}

	public getRiskFilters(analysisId: string, options = new HttpOptionsViewModel()): Promise<AnalysisApplicationRiskFilterOptionModel> {
		options.params = this.httpService.getHttpParams({
			analysisId
		});

		return this.httpService
			.request<AnalysisApplicationRiskFilterOptionModel>(
				'get',
				environment.api + '/api/Analysis/BusinessApplicationLoss/GetRiskFilters',
				options
			)
			.toPromise();
	}

	public getTopByInherentRisk(
		analysisId: string,
		count: number,
		options = new HttpOptionsViewModel()
	): Promise<AnalysisApplicationRiskBaseModel[]> {
		options.params = this.httpService.getHttpParams({
			analysisId,
			count
		});

		return this.httpService
			.request<AnalysisApplicationRiskBaseModel[]>(
				'get',
				environment.api + '/api/Analysis/BusinessApplicationLoss/GetTopByInherentRisk',
				options
			)
			.toPromise();
	}

	public getByAnalysisId(
		analysisId: string,
		pagination: PaginationFilter,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<AnalysisApplicationModel>> {
		options.params = this.httpService.getHttpParams({
			analysisId,
			pagination
		});

		return this.httpService
			.request<Pagination<AnalysisApplicationModel>>(
				'get',
				environment.api + '/api/Analysis/BusinessApplicationLoss/GetByAnalysisId',
				options
			)
			.toPromise();
	}

	public getByAnalysisIdWithNearestThousandRounding(
		analysisId: string,
		pagination: PaginationFilter,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<AnalysisApplicationModel>> {
		options.params = this.httpService.getHttpParams({
			analysisId,
			pagination
		});

		return this.httpService
			.request<Pagination<AnalysisApplicationModel>>(
				'get',
				environment.api + '/api/Analysis/BusinessApplicationLoss/GetByAnalysisIdWithNearestThousandRounding',
				options
			)
			.toPromise();
	}

	public export(analysisId: string, options = new HttpOptionsViewModel()): Promise<Blob> {
		options.responseType = options.responseType ? options.responseType : 'blob';
		options.params = this.httpService.getHttpParams({
			analysisId
		});

		return this.httpService.request<Blob>('get', environment.api + '/api/Analysis/BusinessApplicationLoss/Export', options).toPromise();
	}

	public getRisk(
		analysisBusinessApplicationId: string,
		attackId: string,
		options = new HttpOptionsViewModel()
	): Promise<ImpactVectorRiskModel> {
		options.params = this.httpService.getHttpParams({
			analysisBusinessApplicationId,
			attackId
		});

		return this.httpService
			.request<ImpactVectorRiskModel>('get', environment.api + '/api/Analysis/BusinessApplicationLoss/GetRisk', options)
			.toPromise();
	}
}
