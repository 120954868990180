import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { IActionResult } from '../../models/iaction-result.model';
import { UpdateUserModel } from '../../models/update-user-model.model';
import { CreateUserModel } from '../../models/create-user-model.model';
import { UserModel } from '../../models/user-model.model';
import { BaseUserModel } from '../../models/base-user-model.model';
import { Pagination } from '../../models/pagination.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { UserFilterModel } from '../../models/user-filter-model.model';
import { UserFilterOptionModel } from '../../models/user-filter-option-model.model';
import { ResetPasswordModel } from '../../models/reset-password-model.model';
import { UserActiveModel } from '../../models/user-active-model.model';

@Injectable()
export class UserDataService {
	constructor(private httpService: RQHttpService) {}

	public update(user: UpdateUserModel, options = new HttpOptionsViewModel()): Promise<IActionResult> {
		options.body = user;

		return this.httpService.request<IActionResult>('post', environment.api + '/api/Identity/User/Update', options).toPromise();
	}

	public create(user: CreateUserModel, options = new HttpOptionsViewModel()): Promise<string> {
		options.body = user;

		return this.httpService.request<string>('post', environment.api + '/api/Identity/User/Create', options).toPromise();
	}

	public getById(userId: string, options = new HttpOptionsViewModel()): Promise<UserModel> {
		options.params = this.httpService.getHttpParams({
			userId
		});

		return this.httpService.request<UserModel>('get', environment.api + '/api/Identity/User/GetById', options).toPromise();
	}

	public getCurrent(options = new HttpOptionsViewModel()): Promise<BaseUserModel> {
		return this.httpService.request<BaseUserModel>('get', environment.api + '/api/Identity/User/GetCurrent', options).toPromise();
	}

	public getUsers(
		legalEntityId: string,
		pagination: PaginationFilter,
		userFilterModel: UserFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<UserModel>> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			pagination,
			userFilterModel
		});

		return this.httpService.request<Pagination<UserModel>>('get', environment.api + '/api/Identity/User/GetUsers', options).toPromise();
	}

	public getFilterOptions(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<UserFilterOptionModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<UserFilterOptionModel>('get', environment.api + '/api/Identity/User/GetFilterOptions', options)
			.toPromise();
	}

	public resetPassword(resetPassword: ResetPasswordModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = resetPassword;

		return this.httpService.request<void>('post', environment.api + '/api/Identity/User/ResetPassword', options).toPromise();
	}

	public isExistingUser(userEmail: string, options = new HttpOptionsViewModel()): Promise<boolean> {
		options.params = this.httpService.getHttpParams({
			userEmail
		});

		return this.httpService.request<boolean>('get', environment.api + '/api/Identity/User/IsExistingUser', options).toPromise();
	}

	public changeUserActiveStatus(userStatus: UserActiveModel, options = new HttpOptionsViewModel()): Promise<boolean> {
		options.body = userStatus;

		return this.httpService.request<boolean>('post', environment.api + '/api/Identity/User/ChangeUserActiveStatus', options).toPromise();
	}
}
