import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { ActorViewModel } from '../../models/actor-view-model.model';

@Injectable()
export class ActorDataService {
	constructor(private httpService: RQHttpService) {}

	public getByLegalEntity(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<ActorViewModel[]> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<ActorViewModel[]>('get', environment.api + '/api/Configuration/Actor/GetByLegalEntity', options)
			.toPromise();
	}

	public getByName(legalEntityId: string, actorName: string, options = new HttpOptionsViewModel()): Promise<ActorViewModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			actorName
		});

		return this.httpService.request<ActorViewModel>('get', environment.api + '/api/Configuration/Actor/GetByName', options).toPromise();
	}
}
