<article>
  <div class="notification"
       *ngFor="let notification of notifications">
    <section class="notification-container b">
      <section class="notification-icon error-icon-container m-r-md"
               *ngIf="notification.type === notificationType.Error">
        <rq-icon href="icon-info-circle"
                 class="danger-fill error-icon"></rq-icon>
      </section>
      <section class="notification-content-wrapper">
        <p class="notification-title text-sm">
          {{notification.title}}
        </p>
        <p class="notification-content primary-l-10-color"
           [innerHtml]="notification.message"></p>
      </section>
      <rq-icon href="icon-cancel"
               class="icon-xs icon-cancel primary-l-10-fill"
               (click)="dismissNotification(notification)"></rq-icon>
    </section>
  </div>
</article>
