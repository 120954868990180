import { Injector } from '@angular/core';

export class AppInjector {
	private static injectorValue: Injector;

	public static get injector(): Injector {
		return AppInjector.injectorValue;
	}

	public static set injector(injector: Injector) {
		AppInjector.injectorValue = injector;
	}
}
