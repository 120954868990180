import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { RecommendationTTPModel } from '../../models/recommendation-ttpmodel.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { RecommendationTTPFilterModel } from '../../models/recommendation-ttpfilter-model.model';
import { RecommendationTTPFilterOptionModel } from '../../models/recommendation-ttpfilter-option-model.model';

@Injectable()
export class RecomendationTTPDataService {
	constructor(private httpService: RQHttpService) {}

	public getByControlOption(
		analysisId: string,
		controlOptionId: string,
		paginationModel: PaginationFilter,
		filter: RecommendationTTPFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<RecommendationTTPModel>> {
		options.params = this.httpService.getHttpParams({
			analysisId,
			controlOptionId,
			paginationModel,
			filter
		});

		return this.httpService
			.request<Pagination<RecommendationTTPModel>>('get', environment.api + '/api/Analysis/RecomendationTTP/GetByControlOption', options)
			.toPromise();
	}

	public getByControlOptionFilterOptions(
		analysisId: string,
		controlOptionId: string,
		options = new HttpOptionsViewModel()
	): Promise<RecommendationTTPFilterOptionModel> {
		options.params = this.httpService.getHttpParams({
			analysisId,
			controlOptionId
		});

		return this.httpService
			.request<RecommendationTTPFilterOptionModel>(
				'get',
				environment.api + '/api/Analysis/RecomendationTTP/GetByControlOptionFilterOptions',
				options
			)
			.toPromise();
	}
}
