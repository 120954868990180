import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { RQ_ROUTES } from '../configs/routes.config';

export class RQRouteReuseStrategyService implements RouteReuseStrategy {
	public shouldDetach(_route: ActivatedRouteSnapshot): boolean {
		return false;
	}

	public store(_route: ActivatedRouteSnapshot, _detachedTree: DetachedRouteHandle): void {
		/**/
	}

	public shouldAttach(_route: ActivatedRouteSnapshot): boolean {
		return false;
	}

	public retrieve(_route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
		return null;
	}

	public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
		let result: boolean;

		const isSameRoute = future.routeConfig === curr.routeConfig;

		if (
			future.routeConfig?.path === RQ_ROUTES.pro.riskAnalysis.view.path &&
			curr.routeConfig?.path === RQ_ROUTES.pro.riskAnalysis.view.path
		) {
			result = isSameRoute && future.params.analysisId === curr.params.analysisId;
		} else {
			result = isSameRoute;
		}

		return result;
	}
}
