import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationState } from '../../server/enums/application-state.enum';
import { SystemHealthDataService } from '../../server/services/health/system-health-data.service';
import { RQ_ROUTES } from '../configs/routes.config';

export const migrationGuard = async (): Promise<boolean> => {
	const router = inject(Router);
	const systemHealthDataService = inject(SystemHealthDataService);

	const state = await systemHealthDataService.getState();
	const migrationUrl = RQ_ROUTES.infrastructure.migration.url;

	if (state !== ApplicationState.Ready) {
		await router.navigateByUrl(migrationUrl);

		return false;
	}

	return true;
};
