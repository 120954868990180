import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { AttackViewModel } from '../../models/attack-view-model.model';

@Injectable()
export class EnterpriseAttackDataService {
	constructor(private httpService: RQHttpService) {}

	public getAll(options = new HttpOptionsViewModel()): Promise<AttackViewModel[]> {
		return this.httpService
			.request<AttackViewModel[]>('get', environment.api + '/api/Enterprise/EnterpriseAttack/GetAll', options)
			.toPromise();
	}
}
