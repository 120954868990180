import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ForgotPasswordResult } from '../../routes/security/_common/enums/forgot-password.model';
import { ValidatePasswordResult, ValidatePasswordStatus } from '../../routes/security/_common/enums/reset-password.model';
import { CACHE_KEY } from '../../core/configs/cache.config';
import { ROLE_CONFIG } from '../../core/configs/role.config';
import { ForgotPasswordViewModel } from '../../routes/security/_common/models/forgot-password-view-model.model';
import { LoginViewModel } from '../../routes/security/_common/models/login-view-model.model';
import { ResetPasswordViewModel } from '../../routes/security/_common/models/reset-password-view-model.model';
import { AuthenticationType } from '../enums/authentication-type.enum';
import { HttpOptionsViewModel } from '../../core/models/http-options.model';
import { HttpStatusCode } from '../../core/enums/http-status-code.enum';
import { TokenModel } from '../../core/models/token.model';
import { IDPDataService } from './idp-data.service';
import { LoggerService } from '../../core/services/logger.service';
import { LoginResult } from '../../routes/security/_common/enums/login.model';
import { UserRoleViewModel, UserViewModel } from '../models/user-edit-form.model';
import { LoginExternalProvidersModel } from '../../routes/security/_common/models/login-external-providers-model.model';
import { UserPreferencesViewModel } from '../../server/models/user-preferences-view-model.model';
import { AccountDataService } from '../../server/services/identity/account-data.service';
import { UserDataService } from '../../server/services/identity/user-data.service';
import { UserRolesDataService } from '../../server/services/identity/user-roles-data.service';
import { CachingService } from '../../core/services/caching.service';

@Injectable({
	providedIn: 'root'
})
export class IdentityService {
	public isAuthenticated!: boolean;

	public userPreferencesViewModel!: UserPreferencesViewModel;

	public roles!: string[];

	private user!: UserViewModel;

	private authenticationType!: AuthenticationType;

	private provider!: LoginExternalProvidersModel;

	constructor(
		private accountDataService: AccountDataService,
		private cachingService: CachingService,
		private loggerService: LoggerService,
		private userRolesDataService: UserRolesDataService,
		private idpDataService: IDPDataService,
		private userDataService: UserDataService
	) {}

	public get isExportUserOnly() {
		return this.isExportApi && this.roles.length === 1;
	}

	public get isAdministrator() {
		return this.isProAdministrator || this.isEnterpriseAdministrator || this.isInstanceAdministrator;
	}

	public get isEnterpriseAdministrator() {
		return this.isInRole(ROLE_CONFIG.enterprise.administrator);
	}

	public get isInstanceAdministrator() {
		return this.isInRole(ROLE_CONFIG.instance.administrator);
	}

	public get isProAdministrator() {
		return this.isInRole(ROLE_CONFIG.pro.administrator);
	}

	public get isProEditor() {
		return this.isInRole(ROLE_CONFIG.pro.editor);
	}

	public get isExportApi() {
		return this.isInRole(ROLE_CONFIG.pro.exportApi);
	}

	public get isEnterpriseReadOnly() {
		return this.isInRole(ROLE_CONFIG.enterprise.readOnly);
	}

	public get isProReadOnly() {
		return this.isInRole(ROLE_CONFIG.pro.readOnly);
	}

	public get isReadOnly() {
		return this.isProReadOnly || this.isEnterpriseReadOnly;
	}

	public get isFairOnly() {
		return this.isInRole(ROLE_CONFIG.pro.fairOnly);
	}

	public get isEnterpriseUser() {
		return this.isInRole(ROLE_CONFIG.enterprise.administrator) || this.isInRole(ROLE_CONFIG.enterprise.readOnly);
	}

	public get isProUser() {
		return (
			this.isInRole(ROLE_CONFIG.pro.editor) ||
			this.isInRole(ROLE_CONFIG.pro.readOnly) ||
			this.isInRole(ROLE_CONFIG.pro.administrator) ||
			this.isInRole(ROLE_CONFIG.pro.fairOnly)
		);
	}

	private get isLogoutOnUIEnabled() {
		return this.provider?.externalProviders?.length > 0 && this.provider.externalProviders[0].providerLoginFirst;
	}

	public async getCurrentUser() {
		if (typeof this.user === 'undefined') {
			const data = await Promise.all([this.userDataService.getCurrent(), this.userRolesDataService.getByUser()]);

			this.user = new UserViewModel();
			this.user.id = data[0].id;
			this.user.name = data[0].name;
			this.user.providerUserId = data[0].providerUserId;
			this.user.email = data[0].email;
			this.user.roles = data[1].map(x => {
				const result = new UserRoleViewModel();
				result.name = x;

				return result;
			});
		}

		return this.user;
	}

	public async load() {
		const promise = Promise.all([this.accountDataService.isAuthenticated()]);
		const data = await promise;
		this.isAuthenticated = data[0];

		if (this.isAuthenticated) {
			await Promise.all([this.refreshRoles()]);
		}
	}

	public async getExternalLogins() {
		this.provider = await this.idpDataService.getExternalLogins();

		return this.provider;
	}

	public async login(login: LoginViewModel, isRememberMeChecked: boolean) {
		this.cachingService.removeItem(CACHE_KEY.Token);

		try {
			const token = await this.idpDataService.login(login);

			const result = await this.createStatus(token, isRememberMeChecked);

			await this.getCurrentUser();

			return result;
		} catch (error) {
			return this.handleLoginError(error as HttpErrorResponse);
		}
	}

	public async externalLogin() {
		this.cachingService.removeItem(CACHE_KEY.Token);

		try {
			const options = new HttpOptionsViewModel();
			options.withCredentials = true;

			const token = await this.idpDataService.externalLogin(options);

			return await this.createStatus(token);
		} catch (error) {
			return this.handleLoginError(error as HttpErrorResponse);
		}
	}

	public async isAuthenticationType(type: AuthenticationType) {
		if (!this.authenticationType) {
			this.authenticationType = await this.idpDataService.getAuthenticationType();
		}

		return this.authenticationType === type;
	}

	public isInRole(role: string) {
		if (!this.roles || this.roles.length === 0) {
			return false;
		} else {
			const roleResult = this.roles.find(x => x === role);

			return roleResult !== undefined;
		}
	}

	public async logoutInUI() {
		if (this.isLogoutOnUIEnabled) {
			await this.idpDataService.logout();

			this.cachingService.removeItem(CACHE_KEY.Token);
		}
	}

	public async logout(): Promise<void> {
		if (!this.isLogoutOnUIEnabled) {
			await this.idpDataService.logout();

			this.loggerService.suppressDisplayOfErrors = true;

			this.cachingService.removeItem(CACHE_KEY.Token);
		}
		window.location.href = '/';
	}

	public async forgotPassword(model: ForgotPasswordViewModel): Promise<ForgotPasswordResult> {
		try {
			await this.idpDataService.forgotPassword(model);
			return ForgotPasswordResult.Success;
		} catch (error) {
			return this.handleForgotPasswordError(error as HttpErrorResponse);
		}
	}

	public async validateUnblockToken(model: ResetPasswordViewModel): Promise<ValidatePasswordResult> {
		try {
			await this.idpDataService.validateUnblockToken(model);
			return ValidatePasswordResult.Success;
		} catch (error) {
			return ValidatePasswordResult.Failed;
		}
	}

	public async validateResetPasswordToken(model: ResetPasswordViewModel): Promise<ValidatePasswordResult> {
		try {
			await this.idpDataService.validateResetPasswordToken(model);
			return ValidatePasswordResult.Success;
		} catch (error) {
			return ValidatePasswordResult.Failed;
		}
	}

	public async changePassword(model: ResetPasswordViewModel): Promise<ValidatePasswordStatus> {
		try {
			await this.idpDataService.changePassword(model);
			return ValidatePasswordStatus.ValidPassword;
		} catch (error) {
			return this.handleResetPasswordError(error as HttpErrorResponse);
		}
	}

	public async unlockAccount(model: ResetPasswordViewModel): Promise<ValidatePasswordStatus> {
		try {
			await this.idpDataService.unlockAccount(model);
			return ValidatePasswordStatus.ValidPassword;
		} catch (error) {
			return this.handleResetPasswordError(error as HttpErrorResponse);
		}
	}

	private async createStatus(token: TokenModel, isRememberMeChecked = false) {
		this.cachingService.setItem(CACHE_KEY.Token, token.value, isRememberMeChecked);

		await this.refreshRoles();

		if (this.isExportUserOnly) {
			return LoginResult.UnknownUser;
		}

		return LoginResult.Success;
	}

	private handleLoginError(error: HttpErrorResponse): LoginResult {
		if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.BAD_REQUEST) {
			const hasError = error instanceof HttpErrorResponse && error.error !== undefined && error.error.loginError !== undefined;

			if (hasError) {
				return error.error.loginError as LoginResult;
			}
			return LoginResult.UnknownUser;
		}
		throw error;
	}

	private handleForgotPasswordError(error: HttpErrorResponse): ForgotPasswordResult {
		if (error instanceof HttpErrorResponse) {
			if (error.status === HttpStatusCode.NOT_FOUND) {
				return ForgotPasswordResult.AccountNotFound;
			}

			if (error.status === HttpStatusCode.BAD_REQUEST) {
				return ForgotPasswordResult.UnknownError;
			}
		}

		throw error;
	}

	private async refreshRoles() {
		this.roles = await this.userRolesDataService.getByUser();
	}

	private handleResetPasswordError(error: HttpErrorResponse): ValidatePasswordStatus {
		const hasError = error instanceof HttpErrorResponse && error.error !== undefined && error.error.status !== undefined;

		if (hasError) {
			return error.error.status as ValidatePasswordStatus;
		}
		return ValidatePasswordStatus.InvalidToken;
	}
}
