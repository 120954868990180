import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { ActorViewModel } from '../../models/actor-view-model.model';

@Injectable()
export class EnterpriseActorDataService {
	constructor(private httpService: RQHttpService) {}

	public getAll(options = new HttpOptionsViewModel()): Promise<ActorViewModel[]> {
		return this.httpService
			.request<ActorViewModel[]>('get', environment.api + '/api/Enterprise/EnterpriseActor/GetAll', options)
			.toPromise();
	}
}
