export enum LoginPageState {
	ExternalLoginRedirect = '1',
	ExternalLogoutRedirect = '2'
}

export enum LoginResult {
	UnknownUser = 0,
	ExternalLoginFailed = 1,
	LicenseExpired = 3,
	Success = 4
}
