import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { AnalysisAggregatedThreatsModel } from '../../models/analysis-aggregated-threats-model.model';
import { AnalysisAggregatedThreatFilterModel } from '../../models/analysis-aggregated-threat-filter-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';

@Injectable()
export class AnalysisAggregatedThreatDataService {
	constructor(private httpService: RQHttpService) {}

	public getAnalysisAggregatedThreats(
		legalEntityId: string,
		filterModel: AnalysisAggregatedThreatFilterModel,
		pagination: PaginationFilter,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<AnalysisAggregatedThreatsModel>> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			filterModel,
			pagination
		});

		return this.httpService
			.request<Pagination<AnalysisAggregatedThreatsModel>>(
				'get',
				environment.api + '/api/Analysis/AnalysisAggregatedThreat/GetAnalysisAggregatedThreats',
				options
			)
			.toPromise();
	}
}
