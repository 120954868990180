import { Component, OnInit } from '@angular/core';
import { HomeService } from '../../core/services/recheck/home.service';
import { LegalEntityModel } from '../../server/models/legal-entity-model.model';
import { LegalEntityUserDataService } from '../../server/services/configuration/legal-entity-user-data.service';
import { TranslatePipe } from '../../standalone/pipes/translate.pipe';

@Component({
	standalone: true,
	selector: 'rq-page-not-found',
	templateUrl: './page-not-found.page.html',
	styleUrls: ['./page-not-found.page.scss'],
	imports: [TranslatePipe]
})
export class PageNotFoundComponent implements OnInit {
	public legalEntityModel!: LegalEntityModel;

	constructor(private legalEntityUserDataService: LegalEntityUserDataService, private homeService: HomeService) {}

	public async ngOnInit() {
		const legalEntities = await this.legalEntityUserDataService.getByUser();
		this.legalEntityModel = legalEntities[0];
	}

	public async navigateHome() {
		await this.homeService.navigateHome();
	}
}
