import { ActivatedRouteSnapshot, Params } from '@angular/router';

export function getRouteParams(route: ActivatedRouteSnapshot) {
	let rootRoute: ActivatedRouteSnapshot | null = route ? route.root : null;
	let params: Params = {};

	do {
		if (rootRoute !== null) {
			params = { ...params, ...rootRoute.params };
			rootRoute = rootRoute.firstChild;
		}
	} while (rootRoute !== null);

	return params;
}
