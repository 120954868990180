import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DEFAULT_CURRENCY_CONFIG, DEFAULT_NUMBER_CONFIG, DEFAULT_PERCENT_CONFIG } from '../configs/number-formatter.config';
import { CurrencyConfig, NumberConfig, PercentConfig } from '../models/number.config';
import { TranslationService } from './translation.service';
import { TranslateParam } from '../../standalone/pipes/models/translate.model';

@Injectable({
	providedIn: 'root'
})
export class Customizer {
	public culture = 'en';

	public date = { format: 'MM/DD/YYYY' };

	public dateTime = { format: 'MMMM D, YYYY LT' };

	public isProduction = environment.production;

	public formatter!: { number: NumberConfig; currency: CurrencyConfig; percent: PercentConfig };

	constructor(private translationService: TranslationService) {
		this.formatter = {
			number: new NumberConfig(),
			currency: new CurrencyConfig(),
			percent: new PercentConfig()
		};

		this.configNumberFormatters();
	}

	public get currency() {
		return this.formatter.currency.symbol;
	}

	public set currency(value: string) {
		if (value !== null && value !== undefined) {
			this.formatter.currency.symbol = value;
		}
	}

	public translate = (key: string, param?: TranslateParam) => this.translationService.get(key, param);

	private configNumberFormatters() {
		this.formatter.number = Object.assign(this.formatter.number, DEFAULT_NUMBER_CONFIG);
		this.formatter.percent = Object.assign(this.formatter.percent, DEFAULT_PERCENT_CONFIG);
		this.formatter.currency = Object.assign(this.formatter.currency, DEFAULT_CURRENCY_CONFIG);
	}
}
