import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { SecurityScorecardModel } from '../../models/security-scorecard-model.model';
import { SecurityScorecardScanModel } from '../../models/security-scorecard-scan-model.model';
import { SecurityScorecardScanCreateModel } from '../../models/security-scorecard-scan-create-model.model';
import { SecurityScoreCardSummaryModel } from '../../models/security-score-card-summary-model.model';
import { SecurityScorecardScanUpdateModel } from '../../models/security-scorecard-scan-update-model.model';
import { NotificationMessageModel } from '../../models/notification-message-model.model';

@Injectable()
export class SecurityScorecardScansDataService {
	constructor(private httpService: RQHttpService) {}

	public getByLegalEntityId(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<SecurityScorecardModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<SecurityScorecardModel>('get', environment.api + '/api/Integration/SecurityScorecardScans/GetByLegalEntityId', options)
			.toPromise();
	}

	public getByDomainUrl(
		legalEntityId: string,
		domainUrl: string,
		options = new HttpOptionsViewModel()
	): Promise<SecurityScorecardScanModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			domainUrl
		});

		return this.httpService
			.request<SecurityScorecardScanModel>('get', environment.api + '/api/Integration/SecurityScorecardScans/GetByDomainUrl', options)
			.toPromise();
	}

	public create(createModel: SecurityScorecardScanCreateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = createModel;

		return this.httpService.request<void>('post', environment.api + '/api/Integration/SecurityScorecardScans/Create', options).toPromise();
	}

	public saveOutput(outputModel: SecurityScoreCardSummaryModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = outputModel;

		return this.httpService
			.request<void>('post', environment.api + '/api/Integration/SecurityScorecardScans/SaveOutput', options)
			.toPromise();
	}

	public update(updateModel: SecurityScorecardScanUpdateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = updateModel;

		return this.httpService.request<void>('post', environment.api + '/api/Integration/SecurityScorecardScans/Update', options).toPromise();
	}

	public delete(securityScorecardScanId: string, options = new HttpOptionsViewModel()): Promise<void> {
		options.params = this.httpService.getHttpParams({
			securityScorecardScanId
		});

		return this.httpService
			.request<void>('delete', environment.api + '/api/Integration/SecurityScorecardScans/Delete', options)
			.toPromise();
	}

	public notify(notification: NotificationMessageModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = notification;

		return this.httpService.request<void>('post', environment.api + '/api/Integration/SecurityScorecardScans/Notify', options).toPromise();
	}
}
