import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { RateOfIncidenceModel } from '../../models/rate-of-incidence-model.model';

@Injectable()
export class RateOfIncidenceDataService {
	constructor(private httpService: RQHttpService) {}

	public getByLegalEntityId(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<RateOfIncidenceModel[]> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<RateOfIncidenceModel[]>('get', environment.api + '/api/Settings/RateOfIncidence/GetByLegalEntityId', options)
			.toPromise();
	}

	public revertDefaults(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<void> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService.request<void>('put', environment.api + '/api/Settings/RateOfIncidence/RevertDefaults', options).toPromise();
	}
}
