import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { IdentityService } from '../../shared/services/identity.service';
import { UserPreferencesDataService } from '../../server/services/identity/user-preferences-data.service';
import { RQ_ROUTES } from '../configs/routes.config';

export const termsAndAgreementGuard = async (): Promise<boolean> => {
	const router = inject(Router);
	const userPreferencesDataService = inject(UserPreferencesDataService);
	const identityService = inject(IdentityService);

	identityService.userPreferencesViewModel = await userPreferencesDataService.get();

	if (identityService.userPreferencesViewModel.isTermsAndConditionsEnabled) {
		const isTermsAndConditionsAccepted = identityService.userPreferencesViewModel.isTermsAndConditionsAccepted;

		if (!isTermsAndConditionsAccepted) {
			router.navigateByUrl(RQ_ROUTES.infrastructure.termsAndAgreement.url);
		}

		return isTermsAndConditionsAccepted;
	}

	return true;
};
