import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FileDownloadModel } from '../../models/file-download-model.model';
import { RQFileType } from '../../enums/rqfile-type.enum';

@Injectable()
export class FilesDataService {
	constructor(private httpService: RQHttpService) {}

	public download(fileId: string, options = new HttpOptionsViewModel()): Promise<FileDownloadModel> {
		options.params = this.httpService.getHttpParams({
			fileId
		});

		return this.httpService.request<FileDownloadModel>('get', environment.api + '/api/Infrastructure/Files/Download', options).toPromise();
	}

	public upload(file: File, type: RQFileType, legalEntityId: string, options = new HttpOptionsViewModel()): Observable<HttpEvent<string>> {
		options.params = this.httpService.getHttpParams({
			type,
			legalEntityId
		});

		const formData = new FormData();
		formData.append('file', file, file.name);

		options.reportProgress = true;
		options.body = formData;

		return this.httpService.request<HttpEvent<string>>('post', environment.api + '/api/Infrastructure/Files/Upload', options);
	}
}
