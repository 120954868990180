export const FIDELITY_ONE_ROUTE = {
	mainPath: 'fidelity-one',
	path: 'fidelity-one/:legalEntityId',
	url: fidelityOneUrl,
	configuration: {
		path: 'configuration',
		url: configurationUrl
	},
	dashboard: {
		path: 'dashboard',
		url: dashboardUrl
	}
};

export function fidelityOneUrl(legalEntityId: string) {
	return `fidelity-one/${legalEntityId}`;
}

export function dashboardUrl(legalEntityId: string) {
	return `${fidelityOneUrl(legalEntityId)}/${FIDELITY_ONE_ROUTE.dashboard.path}`;
}

export function configurationUrl(legalEntityId: string) {
	return `${fidelityOneUrl(legalEntityId)}/${FIDELITY_ONE_ROUTE.configuration.path}`;
}
