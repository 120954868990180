import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { ContractProfileCreateModel } from '../../models/contract-profile-create-model.model';
import { ContractProfileModel } from '../../models/contract-profile-model.model';
import { ContractProfileUpdateModel } from '../../models/contract-profile-update-model.model';

@Injectable()
export class ContractProfileDataService {
	constructor(private httpService: RQHttpService) {}

	public create(profileModel: ContractProfileCreateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = profileModel;

		return this.httpService.request<void>('post', environment.api + '/api/Configuration/ContractProfile/Create', options).toPromise();
	}

	public getByLossExposureId(lossExposureId: string, options = new HttpOptionsViewModel()): Promise<ContractProfileModel> {
		options.params = this.httpService.getHttpParams({
			lossExposureId
		});

		return this.httpService
			.request<ContractProfileModel>('get', environment.api + '/api/Configuration/ContractProfile/GetByLossExposureId', options)
			.toPromise();
	}

	public update(profileModel: ContractProfileUpdateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = profileModel;

		return this.httpService.request<void>('post', environment.api + '/api/Configuration/ContractProfile/Update', options).toPromise();
	}
}
