import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { IntegrationAppSocSettingsModel } from '../../models/integration-app-soc-settings-model.model';
import { ActionResult } from '../../models/action-result.model';
import { IntegrationAppSocSettingsCreateModel } from '../../models/integration-app-soc-settings-create-model.model';
import { IntegrationAppSocSettingsUpdateModel } from '../../models/integration-app-soc-settings-update-model.model';
import { IntegrationAppSocSettingsBaseModel } from '../../models/integration-app-soc-settings-base-model.model';

@Injectable()
export class IntegrationAppSocSettingsDataService {
	constructor(private httpService: RQHttpService) {}

	public getByIntegrationId(integrationId: string, options = new HttpOptionsViewModel()): Promise<IntegrationAppSocSettingsModel> {
		options.params = this.httpService.getHttpParams({
			integrationId
		});

		return this.httpService
			.request<IntegrationAppSocSettingsModel>(
				'get',
				environment.api + '/api/Integration/IntegrationAppSocSettings/GetByIntegrationId',
				options
			)
			.toPromise();
	}

	public create(createModel: IntegrationAppSocSettingsCreateModel, options = new HttpOptionsViewModel()): Promise<ActionResult> {
		options.body = createModel;

		return this.httpService
			.request<ActionResult>('post', environment.api + '/api/Integration/IntegrationAppSocSettings/Create', options)
			.toPromise();
	}

	public update(updateModel: IntegrationAppSocSettingsUpdateModel, options = new HttpOptionsViewModel()): Promise<ActionResult> {
		options.body = updateModel;

		return this.httpService
			.request<ActionResult>('post', environment.api + '/api/Integration/IntegrationAppSocSettings/Update', options)
			.toPromise();
	}

	public isConnectionValid(appSocSettings: IntegrationAppSocSettingsBaseModel, options = new HttpOptionsViewModel()): Promise<boolean> {
		options.body = appSocSettings;

		return this.httpService
			.request<boolean>('post', environment.api + '/api/Integration/IntegrationAppSocSettings/IsConnectionValid', options)
			.toPromise();
	}
}
