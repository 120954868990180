import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { CVECreateModel } from '../../models/cvecreate-model.model';

@Injectable()
export class CveDataService {
	constructor(private httpService: RQHttpService) {}

	public updateRange(legalEntityId: string, cves: CVECreateModel[], options = new HttpOptionsViewModel()): Promise<void> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		options.body = cves;

		return this.httpService.request<void>('post', environment.api + '/api/Configuration/Cve/UpdateRange', options).toPromise();
	}
}
