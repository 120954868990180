import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthorizationDataService } from '../../server/services/identity/authorization-data.service';
import { RQ_ROUTES } from '../configs/routes.config';

export const hasProAccessGuard = async (route: ActivatedRouteSnapshot): Promise<boolean> => {
	const router = inject(Router);
	const authorizationDataService = inject(AuthorizationDataService);

	const hasAccessRights = await authorizationDataService.hasProAccessRights(route.params.legalEntityId as string);
	if (!hasAccessRights) {
		await router.navigateByUrl(RQ_ROUTES.infrastructure.pageNotFound.url);
	}

	return hasAccessRights;
};
