import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { EndpointBaseModel } from '../../models/endpoint-base-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { EndpointFilterModel } from '../../models/endpoint-filter-model.model';
import { EndpointFilterOptionModel } from '../../models/endpoint-filter-option-model.model';
import { EndpointUpdateViewModel } from '../../models/endpoint-update-view-model.model';
import { EndpointScoreUpdateModel } from '../../models/endpoint-score-update-model.model';

@Injectable()
export class EndpointDataService {
	constructor(private httpService: RQHttpService) {}

	public getPaginated(
		legalEntityId: string,
		businessApplicationId: string,
		pagination: PaginationFilter,
		filter: EndpointFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<EndpointBaseModel>> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			businessApplicationId,
			pagination,
			filter
		});

		return this.httpService
			.request<Pagination<EndpointBaseModel>>('get', environment.api + '/api/Configuration/Endpoint/GetPaginated', options)
			.toPromise();
	}

	public getFilters(options = new HttpOptionsViewModel()): Promise<EndpointFilterOptionModel> {
		return this.httpService
			.request<EndpointFilterOptionModel>('get', environment.api + '/api/Configuration/Endpoint/GetFilters', options)
			.toPromise();
	}

	public updateRange(updateModels: EndpointUpdateViewModel[], options = new HttpOptionsViewModel()): Promise<void> {
		options.body = updateModels;

		return this.httpService.request<void>('post', environment.api + '/api/Configuration/Endpoint/UpdateRange', options).toPromise();
	}

	public updateScoreRange(updateModels: EndpointScoreUpdateModel[], options = new HttpOptionsViewModel()): Promise<void> {
		options.body = updateModels;

		return this.httpService.request<void>('post', environment.api + '/api/Configuration/Endpoint/UpdateScoreRange', options).toPromise();
	}
}
